import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import info from "../info";
import {
  addDecimal,
  getApprove,
  getBalance,
  getErc1155Approve,
  getErc1155Balance,
  getErc721Approve,
  getErc721Balance,
  getTokenBalance,
  removeDecimal,
} from "./wagmiHooks";

export const numberToHex = (number) => {
  return "0x" + number.toString(16);
};

export function processData(
  text,
  setErrorArray,
  setMergeArray,
  setDataArray,
  errorArray,
  mergeArray,
  setchecking,
  setTextAreaValue,
  setErrorIndex
) {
  setchecking(true);
  setErrorArray([]);
  setDataArray([]);
  setMergeArray([]);
  setErrorIndex([]);
  var canGoon = true;

  var lines = text.split(/\r?\n/);
  lines = lines.filter((e) => e !== "");

  if (lines.length == 1) {
    lines = lines[0].split(" ");
  }

  if (lines.length == 0) {
    toast.error("Please add addresses and amounts", { className: "mt-1" });
    setchecking(false);
    canGoon = false;
    return;
  }

  try {
    const data = lines.map((line, index) => {
      const items = line
        .concat(" ", "")
        .split(",")
        .map((item) => item.trim()); // Satırdaki verileri virgüllere göre ayır

      if (items.length !== 2) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid data at line ${index + 1}: ${line}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
        return null;
      }

      const address = items[0];
      const amount = items[1];

      // Burada adreslerin ve miktarların doğru formatta olup olmadığını kontrol edin
      if (!isValidAddress(address)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid address at line ${index + 1}: ${address}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      if (!isValidAmount(amount)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid amount at line ${index + 1}: ${amount}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      return { address, amount };
    });

    const uniqueAddresses = new Map();
    data.forEach((item, index) => {
      if (item && uniqueAddresses.has(item.address)) {
        const existingIndexArray = uniqueAddresses.get(item.address);
        existingIndexArray.push(index);
        uniqueAddresses.set(item.address, existingIndexArray);
        canGoon = false;
      } else if (item) {
        uniqueAddresses.set(item.address, [index]);
      }
    });

    // Duplicates için sonuç array'ini oluşturma
    const result = [];
    uniqueAddresses.forEach((indices) => {
      if (indices.length > 1) {
        result.push(indices);
        canGoon = false;
      }
    });

    var newText = "";

    data.forEach((e) => {
      newText += e.address + "," + e.amount + "\n";
    });
    setTextAreaValue(newText);

    if (errorArray.length > 0 || result.length > 0) {
      setchecking(false);
      setMergeArray(result);
      setchecking(false);
      setDataArray(data);
      canGoon = false;
    }
    setDataArray(data);
    setchecking(false);
    return canGoon;
  } catch (error) {
    setErrorArray((e) => [
      ...e,
      {
        index: 0,
        message: "Please paste addresses and amounts comma seperated",
      },
    ]);
    setchecking(false);
    return false;
  }
}

function isValidAddress(address) {
  // Ethereum adres formatını burada kontrol edin
  // Örneğin:
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

function isValidAmount(amount) {
  // Miktar formatını burada kontrol edin
  // Örneğin:
  return /^\d+(\.\d+)?$/.test(amount);
}

export const mergeDuplicates = (
  mergeArray,
  dataArray,
  setTextAreaValue,
  setMergeArray,
  setDataArray,
  setErrorArray
) => {
  function sumDuplicatesAndRemove(dataArray, duplicatedAddressesGruped) {
    duplicatedAddressesGruped.forEach((group) => {
      const firstIndex = group[0];
      const firstItem = dataArray[firstIndex];
      let totalAmount = parseFloat(firstItem.amount);

      for (let i = 1; i < group.length; i++) {
        const currentIndex = group[i];
        totalAmount += parseFloat(dataArray[currentIndex].amount);
        delete dataArray[currentIndex];
      }

      dataArray[firstIndex] = {
        address: firstItem.address,
        amount: totalAmount.toString(),
      };
    });

    return dataArray.filter((item) => item != null);
  }
  if (mergeArray.length === 0) {
    return;
  }
  var result = sumDuplicatesAndRemove(dataArray, mergeArray);

  var newText = "";

  result.forEach((e) => {
    newText += e.address + "," + e.amount + "\n";
  });
  setTextAreaValue(newText);
  setDataArray(result);
  setMergeArray([]);
  setErrorArray([]);
};

export const removeDuplicates = (
  mergeArray,
  dataArray,
  setTextAreaValue,
  setMergeArray,
  setDataArray,
  setErrorArray
) => {
  function removeDuplicates1(dataArray, duplicatedAddressesGruped) {
    const uniqueIndices = new Set();
    duplicatedAddressesGruped.forEach((group) => {
      for (let i = 1; i < group.length; i++) {
        uniqueIndices.add(group[i]);
      }
    });

    const result = dataArray.filter((item, index) => !uniqueIndices.has(index));
    return result;
  }

  const result = removeDuplicates1(dataArray, mergeArray);
  var newText = "";

  result.forEach((e) => {
    newText += e.address + "," + e.amount + "\n";
  });
  setTextAreaValue(newText);
  setDataArray(result);
  setMergeArray([]);
  setErrorArray([]);
};

export const prepareForNextStep = async (
  setchecking,
  selectedToken,
  dataArray,
  setStep,
  address,
  setErrorArray,
  setsendingInfo,
  currentNetwork,
  sendingInfo
) => {
  setchecking(true);
  var tokenOrCurrency;
  if (!selectedToken) {
    setErrorArray((prevArray) => [
      ...prevArray,
      { message: "Please select token" },
    ]);
    setchecking(false);
    return;
  }
  //token mı native mi approve gerekli mi???
  if (selectedToken.value !== "0x0000000000000000000000000000000000000000") {
    tokenOrCurrency = "token";
    setsendingInfo((prevInfo) => ({ ...prevInfo, approveNeeded: true }));
  } else {
    tokenOrCurrency = "currency";
    setsendingInfo((prevInfo) => ({ ...prevInfo, approveNeeded: false }));
  }

  //token ise adresi geçerli mi ?
  try {
    if (tokenOrCurrency === "token") {
      const balanceOfToken = await getTokenBalance(selectedToken.value);
      if (balanceOfToken)
        setsendingInfo((prevInfo) => ({
          ...prevInfo,
          token: true,
          native: false,
        }));
    } else {
      setsendingInfo((prevInfo) => ({
        ...prevInfo,
        native: true,
        token: false,
      }));
    }
  } catch (error) {
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      token: false,
      native: false,
      error: true,
    }));
    setErrorArray((prevArray) => [
      ...prevArray,
      "Token not found, please check if token contract exists",
    ]);
  }

  // gerekli işlem adedi
  const numberOfTx =
    Math.floor(dataArray.length / info.tokensenderArrayLength) + 1;
  setsendingInfo((prevInfo) => ({
    ...prevInfo,
    transactionsCount: numberOfTx,
  }));

  // native balans
  try {
    const currencyBalance = await getBalance(address);
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      nativeBalance: new BigNumber(currencyBalance.formatted).toFixed(5),
    }));
  } catch (error) {
    setErrorArray((prevArray) => [
      ...prevArray,
      "Your native currency balance couldn't fetched",
    ]);
  }

  var allowance;

  //token ise contractın allowance değeri
  if (tokenOrCurrency === "token") {
    try {
      allowance = await getApprove(
        address,
        selectedToken.value,
        currentNetwork.tokensenderAddress
      );
      if (allowance.toString()) {
        setsendingInfo((prevInfo) => ({
          ...prevInfo,
          allowance: allowance.toString(),
          allowanceNoDecimal: removeDecimal(
            allowance.toString(),
            selectedToken.decimals,
            0
          ),
        }));
        allowance = allowance.toString();
      } else {
        setErrorArray((prevArray) => [
          ...prevArray,
          "Token allowance couldn't fetch",
        ]);
        allowance = null;
      }
    } catch (error) {
      setErrorArray((prevArray) => [
        ...prevArray,
        "Token allowance couldn't fetch",
      ]);
      allowance = null;
    }

    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      token: tokenOrCurrency,
      decimals: selectedToken.decimals,
    }));
  }

  //gönderilecek toplam rakam
  const getTotalAmountToSend = () => {
    const amounts = dataArray.map((e) => e.amount);
    const amountsDecimal = dataArray.map((e) =>
      addDecimal(e.amount, selectedToken.decimals, 0)
    );
    const addresses = dataArray.map((e) => e.address);
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      amounts: amounts,
      amountsDecimal: amountsDecimal,
      addresses: addresses,
    }));
    var total = 0;
    for (let i = 0; i < amounts.length; i++) {
      total += Number(amounts[i]);
    }
    return total;
  };

  const totalAmount = getTotalAmountToSend();
  setsendingInfo((el) => ({ ...el, totalAmount: totalAmount }));
  const totalWithDecimal = addDecimal(totalAmount, selectedToken.decimals);

  if (tokenOrCurrency === "token") {
    if (Number(allowance) >= Number(totalWithDecimal)) {
      setsendingInfo((prevInfo) => ({
        ...prevInfo,
        allowanceNeeded: false,
        approveAmount: 0,
        approveAmountWithoutDecimal: 0,
      }));
    } else {
      var approveAmount = Number(totalWithDecimal) - Number(allowance);
      var approveAmountWithoutDecimal = removeDecimal(
        approveAmount,
        selectedToken.decimals,
        5
      );
      setsendingInfo((prevInfo) => ({
        ...prevInfo,
        allowanceNeeded: true,
        approveAmount: approveAmount,
        approveAmountWithoutDecimal: approveAmountWithoutDecimal,
      }));
    }
  }
};

// arrayleri daha küçük arraylerden oluşan arraye dönüştürme
export const spliceArrays = (array, count) => {
  return array.reduce((all, one, i) => {
    const ch = Math.floor(i / count);
    all[ch] = [].concat(all[ch] || [], one);
    return all;
  }, []);
};

function isValidId(amount) {
  const num = Number(amount);
  return Number.isInteger(num);
}

export function processDataErc721(
  text,
  setErrorArray,
  setMergeArray,
  setDataArray,
  errorArray,
  mergeArray,
  setchecking,
  setTextAreaValue,
  setErrorIndex
) {
  setchecking(true);
  setErrorArray([]);
  setDataArray([]);
  setMergeArray([]);
  setErrorIndex([]);
  var canGoon = true;

  var lines = text.split(/\r?\n/);
  lines = lines.filter((e) => e !== "");

  if (lines.length == 1) {
    lines = lines[0].split(" ");
  }

  if (lines.length == 0) {
    toast.error("Please add addresses and id's", { className: "mt-1" });
    setchecking(false);
    canGoon = false;
    return;
  }

  try {
    const data = lines.map((line, index) => {
      const items = line
        .concat(" ", "")
        .split(",")
        .map((item) => item.trim()); // Satırdaki verileri virgüllere göre ayır

      if (items.length !== 2) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid data at line ${index + 1}: ${line}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
        return null;
      }

      const address = items[0];
      const amount = items[1];

      // Burada adreslerin ve miktarların doğru formatta olup olmadığını kontrol edin
      if (!isValidAddress(address)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid address at line ${index + 1}: ${address}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      if (!isValidId(amount)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid token Id at line ${index + 1}: ${amount}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      return { address, amount };
    });

    const uniqueAddresses = new Map();
    data.forEach((item, index) => {
      if (item && uniqueAddresses.has(item.amount)) {
        const existingIndexArray = uniqueAddresses.get(item.amount);
        existingIndexArray.push(index);
        uniqueAddresses.set(item.amount, existingIndexArray);
        canGoon = false;
      } else if (item) {
        uniqueAddresses.set(item.amount, [index]);
      }
    });

    // Duplicates için sonuç array'ini oluşturma
    const result = [];
    uniqueAddresses.forEach((indices) => {
      if (indices.length > 1) {
        result.push(indices);
        canGoon = false;
      }
    });

    var newText = "";

    data.forEach((e) => {
      newText += e.address + "," + e.amount + "\n";
    });
    setTextAreaValue(newText);

    if (errorArray.length > 0 || result.length > 0) {
      setchecking(false);
      setMergeArray(result);
      setchecking(false);
      setDataArray(data);
      canGoon = false;
    }
    setDataArray(data);
    setchecking(false);
    return canGoon;
  } catch (error) {
    setErrorArray((e) => [
      ...e,
      {
        index: 0,
        message: "Please paste addresses and id's comma seperated",
      },
    ]);
    setchecking(false);
    return false;
  }
}

export const prepareForNextStepErc721 = async (
  setchecking,
  selectedToken,
  dataArray,
  setStep,
  address,
  setErrorArray,
  setsendingInfo,
  currentNetwork,
  sendingInfo
) => {
  setchecking(true);
  var tokenOrCurrency;
  if (!selectedToken) {
    setErrorArray((prevArray) => [
      ...prevArray,
      { message: "Please select token" },
    ]);
    setchecking(false);
    return;
  }

  try {
    const balanceOfToken = await getErc721Balance(address, selectedToken.value);
    if (balanceOfToken)
      setsendingInfo((prevInfo) => ({
        ...prevInfo,
        balance: balanceOfToken,
      }));
  } catch (error) {
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      error: true,
    }));
    setErrorArray((prevArray) => [
      ...prevArray,
      "NFT not found, please check if NFT contract exists",
    ]);
  }

  // gerekli işlem adedi
  const numberOfTx =
    Math.floor(dataArray.length / info.tokensenderArrayLength) + 1;
  setsendingInfo((prevInfo) => ({
    ...prevInfo,
    transactionsCount: numberOfTx,
  }));

  // native balans
  try {
    const currencyBalance = await getBalance(address);
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      nativeBalance: new BigNumber(currencyBalance.formatted).toFixed(5),
    }));
  } catch (error) {
    setErrorArray((prevArray) => [
      ...prevArray,
      "Your native currency balance couldn't fetched",
    ]);
  }

  const addressesArray = dataArray.map((e) => e.address);

  const amountArray = dataArray.map((e) => e.amount);

  setsendingInfo((prevInfo) => ({
    ...prevInfo,
    amounts: amountArray,
    addresses: addressesArray,
  }));

  var allowance;

  //nft ise contractın allowance değeri
  try {
    allowance = await getErc721Approve(
      address,
      selectedToken.value,
      currentNetwork.tokensenderAddress
    );
    console.log(allowance);
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      allowance: allowance,
    }));
  } catch (error) {
    setErrorArray((prevArray) => [
      ...prevArray,
      "NFT allowance couldn't fetch",
    ]);
    allowance = null;
  }

  setsendingInfo((el) => ({ ...el, totalAmount: dataArray.length }));
};

export function processDataErc1155(
  text,
  setErrorArray,
  setMergeArray,
  setDataArray,
  errorArray,
  mergeArray,
  setchecking,
  setTextAreaValue,
  setErrorIndex
) {
  setchecking(true);
  setErrorArray([]);
  setDataArray([]);
  setMergeArray([]);
  setErrorIndex([]);
  var canGoon = true;

  var lines = text.split(/\r?\n/);
  lines = lines.filter((e) => e !== "");

  if (lines.length == 1) {
    lines = lines[0].split(" ");
  }

  if (lines.length == 0) {
    toast.error("Please add addresses and id's", { className: "mt-1" });
    setchecking(false);
    canGoon = false;
    return;
  }

  try {
    const data = lines.map((line, index) => {
      const items = line
        .concat(" ", "")
        .split(",")
        .map((item) => item.trim()); // Satırdaki verileri virgüllere göre ayır

      if (items.length !== 3) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid data at line ${index + 1}: ${line}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
        return null;
      }

      const address = items[0];
      const id = items[1];

      const amount = items[2];

      // Burada adreslerin ve miktarların doğru formatta olup olmadığını kontrol edin
      if (!isValidAddress(address)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid address at line ${index + 1}: ${address}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      if (!isValidAmount(amount)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid amount at line ${index + 1}: ${amount}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      if (!isValidId(id)) {
        setErrorArray((prevArray) => [
          ...prevArray,
          {
            message: `Invalid token Id at line ${index + 1}: ${id}`,
            index: index + 1,
          },
        ]);
        canGoon = false;
        setErrorIndex((prevIndex) => [...prevIndex, index]);
      }

      return { address, id, amount };
    });

    const uniqueAddresses = {};
    const result = [];

    data.forEach((item, index) => {
      if (item) {
        const key = `${item.address}-${item.id}`;
        if (uniqueAddresses[key]) {
          uniqueAddresses[key].push(index);
        } else {
          uniqueAddresses[key] = [index];
        }
      }
    });

    Object.keys(uniqueAddresses).forEach((key) => {
      if (uniqueAddresses[key].length > 1) {
        result.push(uniqueAddresses[key]);
        canGoon = false;
      }
    });
    console.log(data);
    var newText = "";

    data.forEach((e) => {
      newText += e.address + "," + e.id + "," + e.amount + "\n";
    });
    setTextAreaValue(newText);

    if (errorArray.length > 0 || result.length > 0) {
      setchecking(false);
      setMergeArray(result);
      setchecking(false);
      setDataArray(data);
      canGoon = false;
    }
    setDataArray(data);
    setchecking(false);
    return canGoon;
  } catch (error) {
    console.log(error);
    setErrorArray((e) => [
      ...e,
      {
        index: 0,
        message: "Please paste addresses, id's and amounts comma seperated",
      },
    ]);
    setchecking(false);
    return false;
  }
}

export const prepareForNextStepErc1155 = async (
  setchecking,
  selectedToken,
  dataArray,
  setStep,
  address,
  setErrorArray,
  setsendingInfo,
  currentNetwork,
  sendingInfo
) => {
  setchecking(true);
  if (!selectedToken) {
    setErrorArray((prevArray) => [
      ...prevArray,
      { message: "Please select token" },
    ]);
    setchecking(false);
    return;
  }

  try {
    const balanceOfToken = await getErc1155Balance(
      address,
      selectedToken.value
    );
    if (balanceOfToken)
      setsendingInfo((prevInfo) => ({
        ...prevInfo,
        balance: balanceOfToken,
      }));
  } catch (error) {
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      error: true,
    }));
    setErrorArray((prevArray) => [
      ...prevArray,
      "NFT not found, please check if NFT contract exists",
    ]);
  }

  // gerekli işlem adedi
  const numberOfTx =
    Math.floor(dataArray.length / info.tokensenderArrayLength) + 1;
  setsendingInfo((prevInfo) => ({
    ...prevInfo,
    transactionsCount: numberOfTx,
  }));

  // native balans
  try {
    const currencyBalance = await getBalance(address);
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      nativeBalance: new BigNumber(currencyBalance.formatted).toFixed(5),
    }));
  } catch (error) {
    setErrorArray((prevArray) => [
      ...prevArray,
      "Your native currency balance couldn't fetched",
    ]);
  }

  const addressesArray = dataArray.map((e) => e.address);

  const idArray = dataArray.map((e) => e.id);

  const amountArray = dataArray.map((e) => e.amount);

  setsendingInfo((prevInfo) => ({
    ...prevInfo,
    amounts: amountArray,
    ids: idArray,
    addresses: addressesArray,
  }));

  var allowance;

  //nft ise contractın allowance değeri
  try {
    allowance = await getErc1155Approve(
      address,
      selectedToken.value,
      currentNetwork.tokensenderAddress
    );
    console.log(allowance);
    setsendingInfo((prevInfo) => ({
      ...prevInfo,
      allowance: allowance,
    }));
  } catch (error) {
    setErrorArray((prevArray) => [
      ...prevArray,
      "NFT allowance couldn't fetch",
    ]);
    allowance = null;
  }

  setsendingInfo((el) => ({ ...el, totalAmount: dataArray.length }));
};

export const mergeDuplicatesErc1155 = (
  mergeArray,
  dataArray,
  setTextAreaValue,
  setMergeArray,
  setDataArray,
  setErrorArray
) => {
  function sumDuplicatesAndRemove(dataArray, duplicatedAddressesGruped) {
    duplicatedAddressesGruped.forEach((group) => {
      const firstIndex = group[0];
      const firstItem = dataArray[firstIndex];
      let totalAmount = parseFloat(firstItem.amount);

      for (let i = 1; i < group.length; i++) {
        const currentIndex = group[i];
        totalAmount += parseFloat(dataArray[currentIndex].amount);
        delete dataArray[currentIndex];
      }

      dataArray[firstIndex] = {
        address: firstItem.address,
        id: firstItem.id,
        amount: totalAmount.toString(),
      };
    });

    return dataArray.filter((item) => item != null);
  }
  if (mergeArray.length === 0) {
    return;
  }
  var result = sumDuplicatesAndRemove(dataArray, mergeArray);

  var newText = "";

  result.forEach((e) => {
    newText += e.address + "," + e.id + "," + e.amount + "\n";
  });
  setTextAreaValue(newText);
  setDataArray(result);
  setMergeArray([]);
  setErrorArray([]);
};

export const removeDuplicatesErc1155 = (
  mergeArray,
  dataArray,
  setTextAreaValue,
  setMergeArray,
  setDataArray,
  setErrorArray
) => {
  function removeDuplicates1(dataArray, duplicatedAddressesGruped) {
    const uniqueIndices = new Set();
    duplicatedAddressesGruped.forEach((group) => {
      for (let i = 1; i < group.length; i++) {
        uniqueIndices.add(group[i]);
      }
    });

    const result = dataArray.filter((item, index) => !uniqueIndices.has(index));
    return result;
  }

  const result = removeDuplicates1(dataArray, mergeArray);
  var newText = "";

  result.forEach((e) => {
    newText += e.address + "," + e.id + "," + e.amount + "\n";
  });
  setTextAreaValue(newText);
  setDataArray(result);
  setMergeArray([]);
  setErrorArray([]);
};
