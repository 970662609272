import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap-accordion";
import { Link } from "react-router-dom";

const dataFaq = [
  {
    id: 1,
    title: "Which blockchain networks is it compatible with?",
    content:
      "The DApp is generally compatible with Ethereum and other popular blockchain networks. However, compatibility with supported networks may change based on user demands and updates. To obtain the most up-to-date information, kindly consult the official documentation of the platform.",
  },
  {
    id: 2,
    title:
      "Can I perform transactions with CSV files containing thousands of addresses?",
    content:
      "Certainly, the DApp is capable of processing transactions with CSV files containing thousands of addresses. This feature enables efficient management of large-scale transactions.",
  },
  {
    id: 3,
    title: "What is the referral system, and how does it work?",
    content:
      "The referral system involves providing users who link their wallets on the referral page with a 10% commission from the transactions of individuals they refer. Users can share links received after connecting their wallets to earn commissions from the transactions of those they refer.",
  },
  {
    id: 4,
    title: "What is the fee and how is it determined?",
    content:
      "The fee is determined by the commission rate set by Coinwiz, varying across different blockchain networks. It is not a fixed amount but adapts dynamically based on the transaction speed and network conditions. Users looking for faster transactions can prioritize by paying a higher fee.",
  },
  {
    id: 5,
    title: "Do you have any Questions?",
    content:
      "We are ready to assist you with any of the questions you may have; contact us here.",
  },
];

function Faqs() {
  useEffect(() => {
    const handleAccordionHeight = () => {
      const accordionItems = document.querySelectorAll(".accordion-item");

      accordionItems.forEach((item) => {
        const isExpanded =
          item
            .querySelector(".accordion-button")
            .getAttribute("aria-expanded") === "true";

        if (isExpanded) {
          const accordionCollapse = item.querySelector(".accordion-collapse");
          accordionCollapse.style.height = "auto";
        }
      });
    };

    document.addEventListener("click", handleAccordionHeight);

    return () => {
      document.removeEventListener("click", handleAccordionHeight);
    };
  }, []);
  return (
    <section className="tf-section FAQs">
      <div className="container ">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title">frequently asked questions</h2>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="flat-accordion aos-init aos-animate"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {dataFaq.map((item) => (
                <Accordion
                  className="flat-toggle"
                  key={item.id}
                  title={item.title}
                  show={item.show}
                >
                  <p className="toggle-content">{item.content}</p>
                </Accordion>
              ))}
            </div>
          </div>
          <div className="col-md-12">
            <div className="content_faq text-center">
              <p>If you don`t find an answer, contact us.</p>
              <div className="wrap-btn">
                <Link to="/contact" className="tf-button style1">
                  get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
