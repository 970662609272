import React, { useEffect, useState } from "react";

import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faGithub,
  faTwitter,
  faTelegram,
  faMedium,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";

function Footer(props) {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <footer id="footer">
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="footer-logo">
              <div className="logo_footer">
                <img
                  src={require("../../../assets/images/logo/logo.png")}
                  alt=""
                />
              </div>
              <p>
                Welcome to the future of decentralized finance! Experience a
                user-friendly ecosystem and stay tuned for more innovative
                features, making your Web3 journey seamless.
              </p>
            </div>
            <div className="widget">
              <h5 className="widget-title">Contact us</h5>
              <ul className="widget-link contact">
                <li className="email">
                  <p>Email</p>
                  <a href="mailto:support@coinwiz.app" target="_blank">
                    support@coinwiz.app
                  </a>
                </li>
              </ul>
            </div>
            <div className="widget support">
              <h5 className="widget-title">Tools</h5>
              <ul className="widget-link">
                <li>
                  <Link to="/batchsender">Token Batchsender</Link>
                </li>
                <li>
                  <Link to="/nftsender">NFT Batchsender</Link>
                </li>
              </ul>
            </div>
            <div className="widget link">
              <h5 className="widget-title">Quick link</h5>
              <ul className="widget-link">
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                  <Link to="/referral">Referral</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="wrap-fx">
            <div className="Copyright">
              Copyright © 2024 <a href="https://coinwiz.app">Coinwiz</a>
            </div>
            <ul className="social">
              <li>
                <a href="https://twitter.com/Coinwizapp" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>

              <li>
                <a href="https://t.me/coinwizapp" target="_blank">
                  <FontAwesomeIcon icon={faMedium} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {isVisible && <Link onClick={scrollToTop} to="#" id="scroll-top"></Link>}
    </footer>
  );
}

export default Footer;
