import React from "react";
import { toast } from "react-toastify";
import { ChainIcon } from "connectkit";
import networkInfo from "../networkInfo";

function NetworkList({
  currentNetwork,
  setCurrentNetwork,
  isConnected,
  chain,
}) {
  return (
    <div className="form-inner">
      <ul className="list">
        {currentNetwork &&
          networkInfo.map((e) => (
            <li className="list__item" key={e.chainId}>
              <input
                type="radio"
                key={e.id + e.chainId}
                className="radio-btn"
                name={e.chainId}
                id={"a-opt" + e.chainId}
                checked={isConnected ? e.chainId == chain.id : false}
                onChange={(f) => {
                  if (isConnected) {
                    const thisnetwork = networkInfo.find(
                      (d) => d.chainId == f.target.name
                    );
                    setCurrentNetwork(thisnetwork);
                  } else {
                    toast.error("You need to connect your wallet first", {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "dark",
                    });
                  }
                }}
              />
              <label
                htmlFor={"a-opt" + e.chainId}
                className="label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>{e.name}</span>
                <ChainIcon id={e.chainId} />
              </label>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default NetworkList;
