const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },

  {
    id: 2,
    name: "Apps",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Token Batch Sender",
        links: "/batchsender",
      },
      {
        id: 2,
        sub: "NFT Batch Sender",
        links: "/nftsender",
      },
    ],
  },
  {
    id: 3,
    name: "Contact",
    links: "contact",
  },
  {
    id: 4,
    name: "Share & Earn",
    links: "referral",
  },
];

export default menus;
