import Modal from "react-bootstrap/Modal";

function ExampleListModal({ show, handleClose, erc721 }) {
  if (erc721 && erc721 == "ERC721")
    return (
      <>
        <Modal show={show} onHide={handleClose} data-bs-theme="dark" centered>
          <Modal.Body>
            <div className="text-center">
              <p style={{ color: "white" }}>Example List</p>
              <p style={{ color: "white" }}>
                Please paste addresses and id's comma seperated.
              </p>

              <hr className="mt-3 mb-3" />
            </div>
            <div
              style={{ marginTop: "2rem", color: "white" }}
              className="line-count"
            >
              0x370D71435cC9882bA5682C3B1387D5A252f0CF81,3
              0xD928f36135Cd0362995545234d49110808fa0671,8
              0x61F341b19e1F11F9563b53502B8663A22b77D70C,9
              0xb0C2269e4E4F09FDaAc116669725Be124DBf936a,16
              0xA51b9AC0AA3EdCE1bba7eEbf902CB12966250ecA,25
              0xcf0A55bf3DcfB75692D91A03C27782CBd0a60b40,32
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  if (erc721 && erc721 == "ERC1155")
    return (
      <>
        <Modal show={show} onHide={handleClose} data-bs-theme="dark" centered>
          <Modal.Body>
            <div className="text-center">
              <p style={{ color: "white" }}>Example List</p>
              <p style={{ color: "white" }}>
                Please paste addresses, id's and amounts comma seperated.
              </p>

              <hr className="mt-3 mb-3" />
            </div>
            <div
              style={{ marginTop: "2rem", color: "white" }}
              className="line-count"
            >
              0x370D71435cC9882bA5682C3B1387D5A252f0CF81,5,10
              0xD928f36135Cd0362995545234d49110808fa0671,3,15
              0x61F341b19e1F11F9563b53502B8663A22b77D70C,8,4
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  return (
    <>
      <Modal show={show} onHide={handleClose} data-bs-theme="dark" centered>
        <Modal.Body>
          <div className="text-center">
            <p style={{ color: "white" }}>Example List</p>
            <p style={{ color: "white" }}>
              Please paste addresses and amounts comma seperated.
            </p>

            <hr className="mt-3 mb-3" />
          </div>
          <div
            style={{ marginTop: "2rem", color: "white" }}
            className="line-count"
          >
            0x370D71435cC9882bA5682C3B1387D5A252f0CF81,500.5
            0xD928f36135Cd0362995545234d49110808fa0671,15
            0x61F341b19e1F11F9563b53502B8663A22b77D70C,32.008
            0xb0C2269e4E4F09FDaAc116669725Be124DBf936a,16.8956
            0xA51b9AC0AA3EdCE1bba7eEbf902CB12966250ecA,8.5
            0xcf0A55bf3DcfB75692D91A03C27782CBd0a60b40,0.001
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ExampleListModal;
