import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function PaginatedAddresses({ dataArray }) {
  const [paginated, setpaginated] = useState(null);

  useEffect(() => {
    if (dataArray) {
      setpaginated(dataArray);
    }
  }, [dataArray]);

  return (
    <>
      <div
        className="row"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className="col-md-12 mt-3 text-center addresses-scroll"
          style={{
            width: "95%",
            border: "1px solid #383632",
            backgroundColor: "#171F29",
            borderRadius: "15px",
            padding: "15px",
            color: "white",
            height: "300px",
            overflowY: "scroll",
          }}
        >
          {paginated &&
            paginated.map((e, index) => (
              <div
                style={{
                  justifyContent: "space-between",
                  display: "flex",
                  width: "100%",
                }}
                key={index}
              >
                <div className="address-text">{index + 1}</div>
                <div className="address-text">{e.address}</div>
                {e.id && <div className="address-text">{e.id}</div>}
                <div className="address-text">{e.amount}</div>
              </div>
            ))}
        </div>
      </div>
      <div className="row"></div>
    </>
  );
}

export default PaginatedAddresses;
