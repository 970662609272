import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Icon from "./Icon";

const TooltipEx = ({ id, children, title }) => (
  <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
    <a>{children}</a>
  </OverlayTrigger>
);

const CustomToolTip = ({ id, title, text }) => {
  return (
    <TooltipEx title={title} id={id}>
      {text}
      <Icon
        iconName={"InfoCircleFill"}
        size={15}
        color="white"
        style={{ marginLeft: "5px" }}
      />
    </TooltipEx>
  );
};

export default CustomToolTip;
