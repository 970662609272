import React from "react";
import { Link } from "react-router-dom";
import networkInfo from "./networkInfo";

function Partner4() {
  return (
    <section className="tf-section tf_partner mt-5">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title">Our Partners</h2>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className="partner-wrapper style3"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {networkInfo.map((item) => (
                <Link
                  key={item.link}
                  to={`/tokensender/${item.link}`}
                  className="image"
                >
                  <img src={item.image} alt="" />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partner4;
