import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../menu";
import Button from "../button/index";
import "./styles.scss";
import logo from "../../../assets/images/logo/logo.png";
import ConnectButton from "../ConnectButton";
import Ticker from "react-ticker";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [prices, setPrices] = useState(null);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await fetch(
          "https://api.coincap.io/v2/assets?limit=10"
        );
        const data = await response.json();
        const arr = data.data.filter(
          (e) => e.id !== "tether" && e.id !== "usd-coin"
        );
        setPrices(arr);
      } catch (error) {
        console.log(error);
      }
    };
    getPrices();
    setInterval(() => {
      getPrices();
    }, 5000);
  }, []);

  return (
    <>
      <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
        <div className="container">
          <div id="site-header-inner">
            <div className="header__logo">
              <NavLink to="/">
                <img src={logo} alt="Risebot" />
              </NavLink>
            </div>
            <nav
              id="main-nav"
              className={`main-nav ${menuActive ? "active" : ""}`}
            >
              <ul id="menu-primary-menu" className="menu">
                {menus.map((data, idx) => (
                  <li
                    key={idx}
                    onClick={() => handleDropdown(idx)}
                    className={`menu-item ${
                      data.namesub ? "menu-item-has-children" : ""
                    } ${activeIndex === idx ? "active" : ""}`}
                  >
                    <Link to={data.links}>{data.name}</Link>
                    {data.namesub && (
                      <ul className="sub-menu">
                        {data.namesub.map((submenu) => (
                          <li key={submenu.id} className="menu-item">
                            <NavLink
                              to={submenu.links}
                              onClick={handleDropdown}
                            >
                              {submenu.sub}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <ConnectButton />
            <div
              className={`mobile-button ${menuActive ? "active" : ""}`}
              onClick={handleMenuActive}
            >
              <span></span>
            </div>
          </div>
          <Ticker height={30}>
            {() => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  display: "flex",
                }}
              >
                {prices ? (
                  prices.map((e) => (
                    <div
                      key={e.id}
                      style={{
                        marginRight: "10px",
                        fontSize: "18px",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {e.symbol +
                        " : " +
                        String(Number(e.priceUsd).toFixed(2)) +
                        "$ "}
                    </div>
                  ))
                ) : (
                  <p style={{ visibility: "hidden" }}>Placeholder</p>
                )}
              </div>
            )}
          </Ticker>
        </div>
      </header>
    </>
  );
};

export default Header;
