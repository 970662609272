import { ChainIcon } from "connectkit";
import React from "react";
import { Link } from "react-router-dom";

import networkInfo from "../networkInfo";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper";

const images1 = [
  "0d7938e1-9b3b-4d8b-177b-98188c4cf400.webp",
  "1bf33a89-b049-4a1c-d1f6-4dd7419ee400.webp",
  "204b2240-5ce4-4996-6ec4-f06a22726900.webp",
  "20c3072e-c92e-4902-d4b9-cb2b6ab29100.webp",
  "215158d2-614b-49c9-410f-77aa661c3900.webp",
  "252753e7-b783-4e03-7f77-d39864530900.webp",
  "26a8f588-3231-4411-60ce-5bb6b805a700.webp",
  "2cd67b4c-282b-4809-e7c0-a88cd5116f00.webp",
  "3913df81-63c2-4413-d60b-8ff83cbed500.webp",
  "45f2f08e-fc0c-4d62-3e63-404e72170500.webp",
  "4c16cad4-cac9-4643-6726-c696efaf5200.webp",
  "5195e9db-94d8-4579-6f11-ef553be95100.webp",
  "52b1da3c-9e72-40ae-5dac-6142addd9c00.webp",
  "5b1cddfb-056e-4e78-029a-54de5d70c500.webp",
  "6133c399-ae32-4eba-0c5a-0fb84492bf00.webp",
  "68e8063a-ff69-4941-3b40-af09e2fcd700.webp",
  "6f913b80-86c0-46f9-61ca-cc90a1805900.webp",
  "73f6f52f-7862-49e7-bb85-ba93ab72cc00.webp",
  "7677b54f-3486-46e2-4e37-bf8747814f00.webp",
];

const images2 = [
  "7a33d7f1-3d12-4b5c-f3ee-5cd83cb1b500.webp",
  "7c5ff577-a68d-49c5-02cd-3d83637b0b00.webp",
  "7e1514ba-932d-415d-1bdb-bccb6c2cbc00.webp",
  "877fa1a4-304d-4d45-ca8e-f76d1a556f00.webp",
  "99520548-525c-49d7-fb2f-5db65293b000.webp",
  "9f259366-0bcd-4817-0af9-f78773e41900.webp",
  "a78c4d48-32c1-4a9d-52f2-ec7ee08ce200.webp",
  "a7f416de-aa03-4c5e-3280-ab49269aef00.webp",
  "b6ee4efc-f53e-475b-927b-a7ded6211700.webp",
  "bff9cf1f-df19-42ce-f62a-87f04df13c00.webp",
  "c39b3a16-1a38-4588-f089-cb7aeb584700.webp",
  "d22b2a4b-5562-49ba-506b-6d5986914600.webp",
  "dda0f0fb-34e8-4a57-dcea-b008e7d1ff00.webp",
  "dfe0e3e3-5746-4e2b-12ad-704608531500.webp",
  "e2024511-2c9b-46d7-3111-52df3d241700.webp",
  "ebac7b39-688c-41e3-7912-a4fefba74600.webp",
  "f3119826-4ef5-4d31-4789-d4ae5c18e400.webp",
  "f78dab27-7165-4a3d-fdb1-fcff06c0a700.webp",
  "f9854c79-14ba-4987-42e1-4a82abbf5700.webp",
  "fc460647-ea95-447a-99f0-1bff8fa4be00.webp",
  "fe1b9394-55af-4828-a70d-5c5b7de6b200.webp",
];
SwiperCore.use([Autoplay]);

function Partner4() {
  return (
    <section className="tf-section tf_partner">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title">Supported Chains</h2>
            </div>
          </div>
          {networkInfo.map((e) => (
            <div className="col-md-3" key={e.chainId}>
              <div
                style={{
                  backgroundColor: "#010b3c",
                  borderRadius: "15px",
                  gap: "15px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "15px",
                }}
              >
                <div className="text-center mb-3 mt-1">
                  <ChainIcon id={e.chainId} size={100} />
                  <h5 className="mt-3">{e.name}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row mt-3 mb-3">
          <div className="col-md-12 mt-3 mb-5 text-center">
            <h3>Supported Wallets</h3>
          </div>
          <Swiper
            allowTouchMove={false}
            spaceBetween={5}
            breakpoints={{
              320: {
                slidesPerView: 3,
              },
              480: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 6,
              },
              992: {
                slidesPerView: 8,
              },
              1200: {
                slidesPerView: 9,
              },
              1920: {
                slidesPerView: 10,
              },
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            speed={2000}
          >
            {images1.map((e) => (
              <SwiperSlide key={e}>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: "50%",
                    overFlow: "hidden",
                  }}
                >
                  <img
                    src={require(`../../assets/images/wallets/${e}`)}
                    width="100"
                    style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: "50%",
                      overFlow: "hidden",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="mt-2 mb-2"></div>
          <Swiper
            spaceBetween={5}
            allowTouchMove={false}
            breakpoints={{
              320: {
                slidesPerView: 3,
              },
              480: {
                slidesPerView: 4,
              },
              768: {
                slidesPerView: 6,
              },
              992: {
                slidesPerView: 8,
              },
              1200: {
                slidesPerView: 9,
              },
              1920: {
                slidesPerView: 10,
              },
            }}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
              reverseDirection: true,
            }}
            speed={2000}
          >
            {images2.map((e) => (
              <SwiperSlide key={e}>
                <div
                  style={{
                    margin: 0,
                    padding: 0,
                    borderRadius: "50%",
                    overFlow: "hidden",
                  }}
                >
                  <img
                    src={require(`../../assets/images/wallets/${e}`)}
                    width="100"
                    style={{
                      margin: 0,
                      padding: 0,
                      borderRadius: "50%",
                      overFlow: "hidden",
                    }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
}

export default Partner4;
