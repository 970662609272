import React from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { Link } from "react-router-dom";

import img1 from "../../assets/images/common/icon_1.png";
import img2 from "../../assets/images/common/icon_2.png";
import img3 from "../../assets/images/common/icon_3.png";

const dataFeatured = [
  {
    id: 1,
    img: img1,
    title: "More Time",
    text: "We understand the importance of streamlining transactions to make the most of every moment.Our platform allows you to navigate your financial activities.",
  },
  {
    id: 2,
    img: img2,
    title: "Cost Effective",
    text: "Our commitment to providing a cost-effective solution is designed to empower users, ensuring that financial transactions and activities efficient also economically sensible.",
  },
  {
    id: 3,
    img: img3,
    title: "Secured",
    text: "We acknowledge the crucial significance of protecting your financial transactions and personal information in the ever-changing realm of decentralized finance.",
  },
];

function Featured() {
  return (
    <section className="tf-section fueture">
      <div className="container w_1320">
        <div className="row">
          <div className="col-md-12">
            <div className="container_inner">
              <Swiper
                modules={[Navigation]}
                spaceBetween={30}
                slidesPerView={3}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },

                  867: {
                    slidesPerView: 2,
                  },

                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                {dataFeatured.map((item) => (
                  <SwiperSlide className="pb-30" key={item.id}>
                    <div className="icon-box">
                      <div className="top">
                        <div className="icon">
                          <img src={item.img} alt="" />
                        </div>
                        <div className="content">
                          <h5>
                            <Link to="#">{item.title}</Link>
                          </h5>
                          <p>{item.text}.</p>
                        </div>
                      </div>
                      <div className="bottom">
                        <img
                          src={require("../../assets/images/backgroup/bg_bt_box_1.png")}
                          alt=""
                        />
                      </div>
                      <div className="number">{item.id}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="col-md-12 mt30">
                <p className="desc text-center mb20">
                  If you have any further questions regarding the Coinwiz
                  services,
                  <br className="show-destop" />
                  please do not hesitate to contact us.
                </p>
                <div className="wrap-btn justify-content-center">
                  <Link to="/contact" className="tf-button style1">
                    CONTACT US
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Featured;
