import eth1 from "../assets/images/networksymbols/eth1.png";
import binance1 from "../assets/images/networksymbols/binance1.png";
import fantom1 from "../assets/images/networksymbols/fantom1.png";
import matic1 from "../assets/images/networksymbols/matic1.png";

const networkInfo = [
  {
    id: 1,
    link: "eth",
    name: "Ethereum",
    explorer: "hh2hh",
    rpc: ["ttt22", "ttt21", "tttt20"],
    image: eth1,
    chainId: 1,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://etherscan.io",
  },
  {
    id: 56,
    link: "bsc",
    name: "BSC",
    explorer: "hh1hh",
    rpc: ["ttt12", "ttt11", "t1ttt0"],
    image: binance1,
    chainId: 56,
    currencySymbol: "BNB",
    currencyName: "Binance Coin",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0x475686A19a3783C2B21602c8A018d5252b6F9A80",
    blockExplorer: "https://bscscan.com",
  },

  {
    id: 137,
    link: "matic",
    name: "Polygon",
    explorer: "hh3hh",
    rpc: ["ttt32", "t3tt1", "tttt30"],
    image: matic1,
    chainId: 137,
    currencySymbol: "MATIC",
    currencyName: "Polygon",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://polygonscan.com",
  },
  {
    id: 250,
    link: "fantom",
    name: "Fantom",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: fantom1,
    chainId: 250,
    currencySymbol: "FTM",
    currencyName: "Fantom",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://ftmscan.com",
  },
  {
    id: 43114,
    link: "avax",
    name: "Avalanche",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: eth1,
    chainId: 43114,
    currencySymbol: "AVAX",
    currencyName: "AVAX",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://subnets.avax.network",
  },
  {
    id: 8453,
    link: "base",
    name: "Base",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: eth1,
    chainId: 8453,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://basescan.org",
  },
  {
    id: 42161,
    link: "arbitrum",
    name: "Arbitrum",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: eth1,
    chainId: 42161,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://arbiscan.io",
  },
  {
    id: 59144,
    link: "linea",
    name: "Linea",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: eth1,
    chainId: 59144,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://lineascan.build",
  },
  {
    id: 100,
    link: "gnosis",
    name: "Gnosis",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: eth1,
    chainId: 100,
    currencySymbol: "xDAI",
    currencyName: "xDAI",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://gnosisscan.io",
  },
  {
    id: 10,
    link: "optimism",
    name: "Optimism",
    explorer: "hh4hh",
    rpc: ["ttt24", "ttt41", "ttt4t0"],
    image: eth1,
    chainId: 10,
    currencySymbol: "ETH",
    currencyName: "Ethereum",
    currencyAddress: "0x0000000000000000000000000000000000000000",
    tokensenderAddress: "0xa06B4Eed8079C0F5FB162D395231fe1BB6054Cb2",
    blockExplorer: "https://optimistic.etherscan.io",
  },
];

export default networkInfo;
