import BigNumber from "bignumber.js";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  usePrepareContractWrite,
  useWaitForTransaction,
  useContractWrite,
} from "wagmi";
import { tokenAbi } from "../abi/tokenAbi";
import { tokensenderAbi } from "../abi/tokensenderAbi";
import {
  addDecimal,
  getCommission,
  getCommissionDecimal,
  removeDecimal,
} from "../hooks/wagmiHooks";
import Button from "./button";
import PaginatedAddresses from "./PaginatedAddresses";
import { useDebounce } from "@uidotdev/usehooks";
import TxAlert from "./TxAlert";
import WarningAlert from "./WarningAlert";
import { Spinner } from "react-bootstrap";
import { spliceArrays } from "../hooks/helpers";
import info from "../info";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function TokenSenderSecondStep({
  sendingInfo,
  currentNetwork,
  selectedToken,
  dataArray,
  setreCalculate,
  deflationary,
}) {
  const [sendingSituation, setSendingSituation] = useState(null);

  // approve stateleri
  const [unlimitedAmount, setUnlimitedAmount] = useState(null);
  const [exactAmount, setExactAmount] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [readyToSend, setreadyToSend] = useState(false);
  const [addressesArray, setAddressesArray] = useState(null);
  const [amountsArray, setAmountsArray] = useState(null);
  const debouncedAmount = useDebounce(selectedAmount, 500);
  const [totalTx, settotalTx] = useState(null);
  const [commission, setCommission] = useState(null);
  const [totalCommission, settotalCommission] = useState(null);
  const [currentTx, setCurrentTx] = useState(0);
  const [txArray, setTxArray] = useState(null);
  const [sendingStarted, setSendingStarted] = useState(false);
  const [sendingEnded, setsendingEnded] = useState(false);
  const debouncedTx = useDebounce(totalTx, 1000);
  const [step, setstep] = useState(0);

  const {
    config,
    data,
    error: prepareApproveError,
  } = usePrepareContractWrite({
    address: selectedToken?.value,
    abi: tokenAbi,
    functionName: "approve",
    args: [currentNetwork?.tokensenderAddress, selectedAmount],
    enabled: Boolean(debouncedAmount),
  });

  const {
    data: approveData,
    write: approve,
    error: approveError,
  } = useContractWrite(config);

  const { isLoading: isApproving, isSuccess: isApproved } =
    useWaitForTransaction({
      hash: approveData?.hash,
      onSuccess(data) {
        setreCalculate((pre) => pre + 1);
      },
    });

  const { write: send, data: txData } = useContractWrite({
    address: currentNetwork?.tokensenderAddress,
    abi: tokensenderAbi,
    functionName: "send",

    onError(error) {
      console.log("Error write", error);
      toast.error(error);
      //buraya toast kullanıcı işlemi iptal etti.
    },
    onSuccess(data) {
      console.log("Success write", data);
      updateStatus("pending");
      //kullanıcı işlemi cüzdanda onayladı
    },
    enabled: Boolean(debouncedTx),
  });

  const [ref, setref] = useState(null);
  useEffect(() => {
    const reff = localStorage.getItem("ref");
    setref(reff);
  }, []);

  const waitFortx = useWaitForTransaction({
    hash: txData?.hash,
    onError(error) {
      console.log("Error", error);
      updateStatus("error");
      setCurrentTx((pre) => pre + 1);
    },
    onSuccess(data) {
      console.log("Success", data);
      updateStatus("success");
      setCurrentTx((pre) => pre + 1);
    },
  });

  const sendTx = () => {
    setSendingStarted(true);
    console.log(
      selectedToken.value,
      addressesArray[currentTx],
      amountsArray[currentTx],
      ref,
      commission
    );
    send({
      args: [
        selectedToken.value,
        addressesArray[currentTx],
        amountsArray[currentTx],
        ref,
      ],
      nonce: currentTx,
      value: !sendingInfo.native
        ? commission
        : String(Number(commission) + sumArray(amountsArray[currentTx])),
    });
  };

  useEffect(() => {
    if (currentTx > 0 && currentTx < totalTx && sendingStarted) {
      console.log(
        selectedToken.value,
        addressesArray[currentTx],
        amountsArray[currentTx],
        ref,
        commission
      );
      send({
        args: [
          selectedToken.value,
          addressesArray[currentTx],
          amountsArray[currentTx],
          ref,
        ],
        nonce: currentTx,
        value: !sendingInfo.native
          ? commission
          : String(Number(commission) + sumArray(amountsArray[currentTx])),
      });
    } else if (totalTx > 0 && currentTx >= totalTx) {
      setSendingStarted(false);
      setsendingEnded(true);
    }
  }, [currentTx]);

  useEffect(() => {
    if (txData) {
      var newArr = txArray;
      newArr[currentTx].txHash = txData.hash;
    }
  }, [txData]);

  const updateStatus = (status) => {
    var newArr = txArray;
    newArr[currentTx].status = status;
  };

  const sumArray = (arr) => {
    let toplam = 0;
    for (let i = 0; i < arr.length; i++) {
      toplam += Number(arr[i]);
    }
    console.log("toplam", toplam);
    return toplam;
  };

  useEffect(() => {
    if (sendingInfo) {
      const unlimitedAmountc = addDecimal(
        Number.MAX_SAFE_INTEGER,
        selectedToken.decimals,
        0
      );
      setUnlimitedAmount(unlimitedAmountc);

      const exactAmountc = new BigNumber(sendingInfo.approveAmount).toFixed(0);
      setExactAmount(exactAmountc);
      setSelectedAmount(unlimitedAmountc);
      const func = async () => {
        const info = await getCommissionDecimal(
          currentNetwork.tokensenderAddress
        );
        setCommission(info);
      };
      setSendingSituation((el) => ({
        ...el,
        enoughBalance: Number(sendingInfo.totalAmount),
      }));
      func();
    }
  }, [sendingInfo?.approveAmount, currentNetwork, selectedToken]);

  useEffect(() => {
    setreadyToSend(!sendingInfo?.allowanceNeeded);
  }, [sendingInfo, sendingInfo?.allowanceNeeded]);

  useEffect(() => {
    if (readyToSend) {
      const adAr = spliceArrays(
        sendingInfo.addresses,
        info.tokensenderArrayLength
      );
      const amAr = spliceArrays(
        sendingInfo.amountsDecimal,
        info.tokensenderArrayLength
      );

      const txcount =
        Math.floor(dataArray.length / info.tokensenderArrayLength) + 1;
      settotalTx(txcount);
      const txs = adAr.map((e, index) => {
        return {
          txNo: index + 1,
          txHash: "",
          status: "",
          addresses: adAr[index],
          amounts: amAr[index],
        };
      });
      setTxArray(txs);

      setAddressesArray(adAr);
      setAmountsArray(amAr);
    }
  }, [readyToSend, sendingInfo, dataArray]);

  const validateTx = () => {
    console.log(sendingInfo, selectedToken);
    if (sendingInfo.token == "token") {
      return (
        Number(selectedToken.amount) >= sendingInfo.totalAmount ||
        sendingInfo.nativeBalance >= Number(totalCommission)
      );
    } else {
      return (
        Number(selectedToken.amount) >=
        sendingInfo.totalAmount + Number(totalCommission)
      );
    }
  };

  useEffect(() => {
    if (commission && totalTx) {
      const commissionWd = Number(removeDecimal(commission, 18, 6)) * totalTx;
      settotalCommission(commissionWd);
    }
  }, [commission, totalTx]);

  useEffect(() => {
    console.log(sendingEnded);
  }, [sendingEnded]);

  return (
    <>
      {step == 0 && (
        <div className="row">
          <div className="col-6 text-center mt-3">
            {sendingInfo.token == "token" ? (
              <div
                style={{
                  border: `1px solid ${
                    Number(selectedToken.amount) >= sendingInfo.totalAmount
                      ? "green"
                      : "red"
                  }`,
                  borderRadius: "15px",
                  backgroundColor: "#171F29",

                  padding: "10px",
                  height: "100%",
                }}
                className={`text-break ${
                  Number(selectedToken.amount) >= sendingInfo.totalAmount
                    ? "green-shadow"
                    : "red-shadow"
                }`}
              >
                <h5 className="responsive-text">
                  {sendingInfo.totalAmount.toFixed(5) +
                    " " +
                    selectedToken.symbol}
                </h5>
                <p>
                  {Number(selectedToken.amount) >= sendingInfo.totalAmount
                    ? `Total ${selectedToken.symbol} to be send`
                    : `Total ${selectedToken.symbol} to be send you have not enough balance for transaction`}
                </p>
              </div>
            ) : (
              <div
                style={{
                  border: `1px solid ${
                    Number(selectedToken.amount) >= sendingInfo.totalAmount
                      ? "green"
                      : "red"
                  }`,
                  backgroundColor: "#171F29",

                  borderRadius: "15px",
                  padding: "10px",
                  height: "100%",
                }}
                className={`text-break ${
                  Number(selectedToken.amount) >= sendingInfo.totalAmount
                    ? "green-shadow"
                    : "red-shadow"
                }`}
              >
                <h5 className="responsive-text">
                  {sendingInfo.totalAmount + " " + selectedToken.symbol}
                </h5>
                <p>
                  {Number(sendingInfo.nativeBalance) >= sendingInfo.totalAmount
                    ? `Total ${selectedToken.symbol} to be send`
                    : `Total ${selectedToken.symbol} to be send you have not enough balance for transaction`}
                </p>
              </div>
            )}
          </div>
          <div className="col-6 text-center mt-3">
            <div
              style={{
                border: "1px solid green",
                borderRadius: "15px",
                padding: "10px",
                height: "100%",
                backgroundColor: "#171F29",
              }}
              className="text-break green-shadow"
            >
              <h5 className="responsive-text">
                {sendingInfo.transactionsCount.toString() + " Tx"}
              </h5>
              <p>
                You need to confirm
                {" " + sendingInfo.transactionsCount.toString() + " "}
                transactions on your wallet.
              </p>
            </div>
          </div>
          <div className="col-6 text-center mt-3">
            {sendingInfo.token == "token" ? (
              <div
                style={{
                  border: `1px solid ${
                    Number(selectedToken.amount) >= sendingInfo.totalAmount
                      ? "green"
                      : "red"
                  }`,
                  backgroundColor: "#171F29",

                  borderRadius: "15px",
                  padding: "10px",
                  height: "100%",
                }}
                className={`text-break ${
                  Number(selectedToken.amount) >= sendingInfo.totalAmount
                    ? "green-shadow"
                    : "red-shadow"
                }`}
              >
                <h5 className="responsive-text">
                  {selectedToken.amount + " " + selectedToken.symbol}
                </h5>
                <p>
                  {Number(selectedToken.amount) >= sendingInfo.totalAmount
                    ? "You have enough balance for transaction"
                    : "You have not enough balance for transaction"}
                </p>
              </div>
            ) : (
              <div
                style={{
                  border: `1px solid ${
                    Number(sendingInfo.nativeBalance) >= sendingInfo.totalAmount
                      ? "green"
                      : "red"
                  }`,
                  borderRadius: "15px",
                  padding: "10px",
                  backgroundColor: "#171F29",

                  height: "100%",
                }}
                className={`text-break ${
                  Number(sendingInfo.nativeBalance) >= sendingInfo.totalAmount
                    ? "green-shadow"
                    : "red-shadow"
                }`}
              >
                <h5 className="responsive-text">
                  {sendingInfo.nativeBalance +
                    " " +
                    currentNetwork.currencySymbol}
                </h5>
                <p>
                  {Number(sendingInfo.nativeBalance) >= sendingInfo.totalAmount
                    ? ` You have enough ${
                        " " + currentNetwork.currencySymbol + " "
                      }  to run transaction.`
                    : `You don't have enough ${
                        " " + currentNetwork.currencySymbol + " "
                      } balance`}
                </p>
              </div>
            )}
          </div>
          <div className="col-6 text-center mt-3">
            {sendingInfo.token == "token" && (
              <div
                style={{
                  border: `1px solid ${
                    Number(sendingInfo.allowanceNoDecimal) >=
                    sendingInfo.totalAmount
                      ? "green"
                      : "red"
                  }`,
                  backgroundColor: "#171F29",

                  borderRadius: "15px",
                  padding: "10px",
                  height: "100%",
                }}
                className={`text-break ${
                  Number(sendingInfo.allowanceNoDecimal) >=
                  sendingInfo.totalAmount
                    ? "green-shadow"
                    : "red-shadow"
                }`}
              >
                <h5 className="responsive-text">
                  {Number(sendingInfo.allowanceNoDecimal).toFixed(5) +
                    " " +
                    selectedToken.symbol}
                </h5>
                <p>
                  {Number(sendingInfo.allowanceNoDecimal) >=
                  sendingInfo.totalAmount
                    ? "Allowance amount is enough. You don't need to approvement you can directly start transactions."
                    : "Allowance amount not enough please click button below and give allowance to coinwiz contract."}
                </p>
              </div>
            )}
          </div>
          <div className="form-inner">
            <PaginatedAddresses dataArray={dataArray} />
          </div>
          {sendingInfo && sendingInfo.allowanceNeeded && (
            <div className="form-inner mt-5">
              <ul className="list" style={{ justifyContent: "space-around" }}>
                <li className="list__item" key={"first"}>
                  <input
                    type="radio"
                    className="radio-btn"
                    name={"first"}
                    id={"a-opt1"}
                    checked={unlimitedAmount === selectedAmount}
                    value={unlimitedAmount}
                    onChange={(f) => {
                      setSelectedAmount(unlimitedAmount);
                    }}
                  />
                  <label
                    htmlFor={"a-opt1"}
                    className="label"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>{"Approve unlimited amount"}</span>
                  </label>
                </li>
                <li className="list__item" key={"first1"}>
                  <input
                    type="radio"
                    className="radio-btn"
                    name={"second"}
                    id={"a-opt12"}
                    value={exactAmount}
                    checked={exactAmount === selectedAmount}
                    onChange={(f) => {
                      setSelectedAmount(exactAmount);
                    }}
                  />
                  <label
                    htmlFor={"a-opt12"}
                    className="label"
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <span>
                      {"Exact amount " +
                        sendingInfo.approveAmountWithoutDecimal}
                    </span>
                  </label>
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
      {step == 1 && (
        <div className="row">
          <div className="col-12 text-center">
            <h5>{`You need to complete ${totalTx && totalTx} transactions`}</h5>
            <h5>{`Fee ${totalCommission && totalCommission} ${
              currentNetwork?.currencySymbol
            } + Network Fee`}</h5>
            <h5>{`You are currently completed ${currentTx && currentTx} of ${
              totalTx && totalTx
            }`}</h5>
            {sendingStarted && (
              <h4 style={{ color: "yellow" }}>
                Transactions have been started, Please don't close this page and
                wait till the end!!!
              </h4>
            )}
            {sendingEnded && (
              <h4 style={{ color: "green" }}>
                All transactions completed please click <Link to="/">here</Link>
                to go back.
              </h4>
            )}
          </div>
        </div>
      )}
      {isApproving && (
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <TxAlert
              text={
                "  Your transaction sent to the blockchain. Clik here to see on block explorer."
              }
              link={currentNetwork.blockExplorer + "/tx/" + approveData?.hash}
            />
          </div>
        </div>
      )}
      {approveError && (
        <WarningAlert
          text={
            "This token may not be able to be approved, or there is another error"
          }
        />
      )}
      {txArray && (
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            {txArray.map((e, index) => {
              if (e.status === "pending") {
                return (
                  <TxAlert
                    text={`Transaction number ${e.txNo} is sending to the blockchain click here to see on block explorer`}
                    link={currentNetwork.blockExplorer + "/tx/" + txData?.hash}
                    key={e.txNo}
                  />
                );
              } else if (e.status === "success") {
                return (
                  <TxAlert
                    text={`Transaction number ${e.txNo} is confirmed`}
                    link={currentNetwork.blockExplorer + "/tx/" + e.txHash}
                    status="success"
                    noAnimation={true}
                    key={e.txNo}
                  />
                );
              } else if (e.status === "error") {
                return (
                  <TxAlert
                    text={`Transaction number ${e.txNo} is failed and transaction is not confirmed`}
                    link={currentNetwork.blockExplorer + "/tx/" + e.txHash}
                    status="danger"
                    noAnimation={true}
                    key={e.txNo}
                  />
                );
              }
            })}
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "15px",
        }}
      >
        {sendingInfo &&
          (sendingInfo.allowanceNeeded ? (
            isApproving ? (
              <Spinner animation="border" variant="secondary" />
            ) : (
              <Button
                onClick={approve}
                addclass="style1"
                title="Approve >>"
                style={{ width: "20%" }}
              />
            )
          ) : (
            !sendingStarted &&
            !sendingEnded && (
              <Button
                onClick={() =>
                  step == 0
                    ? validateTx()
                      ? setstep((pre) => pre + 1)
                      : toast.error("Not enough balance you can't proceed.")
                    : sendTx()
                }
                addclass="style1"
                title={step == 0 ? "Next >>" : "Send >"}
                style={{ width: "20%" }}
              />
            )
          ))}
      </div>
    </>
  );
}

export default TokenSenderSecondStep;
