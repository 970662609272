import React from "react";

import img1 from "../../assets/images/common/icon_9.png";
import img2 from "../../assets/images/common/icon_10.png";
import img3 from "../../assets/images/common/icon_11.png";

const dataStep = [
  {
    id: 1,
    img: img1,
    title: "NFT Batchsender",
    text: "The ability to send your NFTs (Non-Fungible Tokens) to multiple addresses in a single, efficient transaction. We understand the value of your time and resources, and our platform is designed to empower you to manage your NFTs with unprecedented ease, saving both time and money.",
  },
  {
    id: 2,
    img: img2,
    title: "Token Batchsender",
    text: "Embark on a new era of convenience and efficiency with our platform, where we empower you to transfer your ERC20 tokens and native coins to multiple wallets in a single, seamless transaction. Our innovative feature is designed to simplify the process, allowing you to manage your digital assets with unparalleled ease.",
  },
  {
    id: 3,
    img: img3,
    title: "Token Generator",
    text: "Step into the world of unprecedented creativity and financial innovation with our platform, where you can bring your vision to life by creating ERC20 tokens effortlessly and without the need for any coding language. Empower your ideas, projects, and communities with the freedom to design tokens that align perfectly with your unique goals and aspirations.",
  },
];

function Step() {
  return (
    <section className="tf-section three_step mt-5">
      <div className="overlay mt-5"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div
              className="tf-title mb46 left"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <h2 className="title">
                Valid for <br className="show-destop" />
                all purposes
              </h2>
            </div>
            <div className="wrap-box">
              {dataStep.map((item) => (
                <div key={item.id} className="icon-box-style3">
                  <div className="icon">
                    <img src={item.img} alt="" />
                  </div>
                  <div className="content">
                    <h5 className="title">{item.title}</h5>
                    <p>{item.text}</p>
                  </div>
                  <img
                    className="icon_bottom"
                    src={require("../../assets/images/common/Line.png")}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-md-6">
            <div className="image pt30">
              <img
                src={require("../../assets/images/common/img_step.png")}
                className="move5"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Step;
