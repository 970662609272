import React from "react";
import { Alert } from "react-bootstrap";
import Icon from "./Icon";

function WarningAlert({ text }) {
  return (
    <Alert variant={"danger"} data-bs-theme="dark">
      <Icon
        iconName={"ExclamationTriangleFill"}
        style={{ marginBottom: "5px", marginRight: "3px" }}
      />
      {text}
    </Alert>
  );
}

export default WarningAlert;
