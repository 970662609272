import React from "react";
import Banner from "./components/banner/Banner";
import Featured from "./components/Featured";
import Technology from "./components/Technology";
import Faqs from "./components/Faqs";
import CTA from "./components/Cta";
import Step from "./components/Step";
import Partner4 from "./components/Partner";
import ReactGA from "react-ga4";

function Home() {
  return (
    <div className="header-fixed main home2 counter-scroll">
      {<Banner />}

      {<Featured />}

      <div className="bg_body">{<Technology />}</div>

      {<Step />}

      {<Partner4 />}
      {<Faqs />}

      {<CTA />}
    </div>
  );
}

export default Home;
