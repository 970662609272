import React from "react";
import Switch from "react-switch";

function CustomSwitch({ checked, onChange }) {
  return (
    <>
      <label htmlFor="small-radius-switch">
        <Switch
          checked={checked}
          onChange={onChange}
          handleDiameter={28}
          offColor="#798da3"
          onColor="#00FFFF"
          offHandleColor="#1E2835"
          onHandleColor="#1E2835"
          height={40}
          width={70}
          borderRadius={6}
          activeBoxShadow="0px 0px 1px 2px #fffc35"
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 15,
                color: "orange",
                paddingRight: 2,
              }}
            ></div>
          }
          checkedIcon={false}
          uncheckedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 20,
              }}
            ></div>
          }
          checkedHandleIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                color: "red",
                fontSize: 18,
              }}
            ></div>
          }
          className="react-switch"
          id="small-radius-switch"
        />
      </label>
    </>
  );
}

export default CustomSwitch;
