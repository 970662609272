import React from "react";
import { Link } from "react-router-dom";
import info from "./info";
import { removeDecimal } from "./hooks/wagmiHooks";
import { useAccount, useContractRead } from "wagmi";
import { useState } from "react";
import { useEffect } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import { toast } from "react-toastify";
import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  GabIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  XIcon,
} from "react-share";
import networkInfo from "./networkInfo";
import { tokensenderAbi } from "./abi/tokensenderAbi";
import { ChainIcon } from "connectkit";
import ReactGA from "react-ga4";

const ShareButtons = ({ shareUrl, title }) => {
  const socialButtons = [
    EmailShareButton,
    TwitterShareButton,
    FacebookShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    RedditShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PocketShareButton,
    TumblrShareButton,
    VKShareButton,
    WorkplaceShareButton,
  ];

  const shareIcons = [
    EmailIcon,
    XIcon,
    FacebookIcon,
    WhatsappIcon,
    TelegramIcon,
    RedditIcon,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PocketIcon,
    TumblrIcon,
    VKIcon,
    WorkplaceIcon,
  ];

  const RenderIcon = ({ icon: Icon }) => {
    return <Icon size={40} round={true} />;
  };
  const [expanded, setexpanded] = useState(false);
  return (
    <div>
      {socialButtons.map((Button, index) => {
        if (!expanded) {
          if (index < 5) {
            return (
              <Button
                key={index}
                url={shareUrl}
                title={""}
                className="share-button"
                style={{ marginLeft: "3px", marginTop: "3px" }}
              >
                <RenderIcon icon={shareIcons[index]} />
              </Button>
            );
          }
        } else {
          return (
            <Button
              key={index}
              url={shareUrl}
              title={""}
              className="share-button"
              style={{ marginLeft: "3px", marginTop: "3px" }}
            >
              <RenderIcon icon={shareIcons[index]} />
            </Button>
          );
        }
      })}
      <span
        style={{
          marginLeft: "8px",
          marginTop: "5px",
          color: "white",
          cursor: "pointer",
          "&:hover": {
            color: "black",
          },
        }}
        onClick={() => setexpanded((pre) => !pre)}
      >
        {!expanded ? " See More..." : "See Less..."}
      </span>
    </div>
  );
};

function Refs({ chain, address }) {
  const { data, isError, isLoading } = useContractRead({
    address: chain.tokensenderAddress,
    abi: tokensenderAbi,
    functionName: "feeIncome",
    args: [address],
    chainId: chain.chainId,
  });
  const [amount, setamount] = useState("0");
  useEffect(() => {
    const formatAmount = () => {
      if (data) {
        console.log(data.toString());
        if (Number(data.toString()) > 0) {
          const formatted = removeDecimal(data.toString(), 18, 4);
          return formatted;
        } else if (Number(data.toString()) == 0) {
          return "0.0000";
        }
      } else {
        return "0";
      }
    };
    const amt = formatAmount();
    console.log(amt);
    setamount(amt);
  }, [data]);

  return amount ? (
    <>
      <li>
        <Link to="#">
          <ChainIcon id={chain.chainId} size={37} />
          <span style={{ marginLeft: "8px" }}>{chain.name}</span>
          <span style={{ marginLeft: "8px" }}>
            {amount + " " + chain.currencySymbol}
          </span>
        </Link>
      </li>
    </>
  ) : (
    <>
      <li>
        <Link to="#">
          <ChainIcon id={chain.chainId} size={37} />
          <span style={{ marginLeft: "8px" }}>{chain.name}</span>
          <span style={{ marginLeft: "8px" }}>{"can't fetched"}</span>
        </Link>
      </li>
    </>
  );
}

function Referral() {
  const isSmallDevice = useMediaQuery(
    "only screen and (min-width : 450px) and (max-width : 768px)"
  );
  const isExtraSmallDevice = useMediaQuery(
    "only screen and (max-width : 450px)"
  );

  const { address, isConnected } = useAccount();
  const [link, setlink] = useState("");
  const title = "CoinWiz Empower Your Transactions & Simplify Your Wallet";
  const returnSize = () => {
    if (isSmallDevice) {
      return "10px";
    } else if (isExtraSmallDevice) {
      return "9px";
    } else {
      return null;
    }
  };
  const [shareUrl, setShareUrl] = useState(null);

  useEffect(() => {
    setlink(window.location.origin);
  }, []);

  useEffect(() => {
    if (isConnected && address) {
      setShareUrl(window.location.origin + "?ref=" + address);
    }
  }, [isConnected, address]);

  const copyLink = () => {
    if (isConnected && address) {
      window.navigator.clipboard.writeText(
        window.location.origin + "?ref=" + address
      );
      toast.success("Your link copied to clipboard.");
    } else {
      toast.error("Please connect your wallet to copy your link");
    }
  };

  return (
    <>
      <div className="inner-page home-2">
        <section className="page-title">
          <div className="overlay"></div>
        </section>

        <section
          className="tf-section tf-wallet"
          data-aos-delay="500"
          data-aos="fade-up"
          data-aos-duration="800"
        >
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="wallet-form">
                  <h4>Share Us Get Commission</h4>
                  <p className="sub">
                    Connect your wallet to watch your referral income in all
                    chains.
                  </p>
                  <div className="spacing"></div>

                  <div className="choose-network flat-tabs">
                    <h6>
                      {!isConnected &&
                        "Connect Your Wallet & Get Your Referral Link"}
                    </h6>
                    <p>
                      Get your {info.refPercent}% share from each transaction
                      you referred.
                    </p>
                    <p>
                      Commissions instantly sending to your wallet by smart
                      contract.
                    </p>
                    <ul className="menu-tab">
                      <li onClick={copyLink}>
                        {isConnected && (
                          <img
                            src={require("../assets/images/common/wallet_1.png")}
                            alt=""
                          />
                        )}
                        <span style={{ fontSize: returnSize() }}>
                          {isConnected
                            ? link + "?ref=" + address
                            : "   Please Connect to get your link"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {address && shareUrl && (
                    <ShareButtons shareUrl={shareUrl} title={title} />
                  )}
                  <div className="connect-wallet mt-3">
                    <h6>Your Referral Rewards</h6>
                    <ul>
                      {address &&
                        networkInfo.map((e) => (
                          <Refs key={e.id} chain={e} address={address} />
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Referral;
