import React, { useEffect, useRef, useState } from "react";
import networkInfo from "./networkInfo";
import { useAccount, useFeeData } from "wagmi";
import { getNetwork, switchNetwork, watchNetwork } from "@wagmi/core";
import { useGlobalState } from "./hooks/globalState";
import PageTitle from "./components/PageTitle";
import { toast } from "react-toastify";
import {
  mergeDuplicatesErc1155,
  prepareForNextStepErc1155,
  prepareForNextStepErc721,
  processDataErc1155,
  processDataErc721,
  removeDuplicatesErc1155,
} from "./hooks/helpers";
import CustomSwitch from "./components/CustomSwitch";
import ExampleListModal from "./components/ExampleListModal";
import getCsv from "./hooks/getCsv";
import CustomToolTip from "./components/ToolTipEx";
import NetworkList from "./components/NetworkList";
import WarningAlert from "./components/WarningAlert";
import ConnectButton from "./components/ConnectButton";
import Button from "./components/button";
import { Spinner } from "react-bootstrap";
import SelectNft from "./components/SelectNft";
import NftSenderSecondStep from "./components/NftSenderSecondStep";
import NftSenderSecondStep1155 from "./components/NftSenderSecondStepErc1155";
import ReactSpeedometer from "react-d3-speedometer";
import ReactGA from "react-ga4";

function Nftsender() {
  const { address, isConnected } = useAccount();
  const { chain } = getNetwork();
  const { globalState, setGlobalState } = useGlobalState();
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [erc721, seterc721] = useState(true);
  const [showExampleList, setShowExampleList] = useState(false);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [lines, setLines] = useState([]);
  const [errorArray, setErrorArray] = useState([]);
  const [mergeArray, setMergeArray] = useState([]);
  const [dataArray, setDataArray] = useState(null);
  const [checking, setchecking] = useState(false);
  const [errorIndex, setErrorIndex] = useState([]);
  const [selectedToken, setSelectedToken] = useState(null);
  const [step, setStep] = useState(0);
  const [sendingInfo, setsendingInfo] = useState(null);
  const [wantToGoNext, setWantToGoNext] = useState(false);
  const [reCalculate, setreCalculate] = useState(0);
  const textareaRef = useRef();
  const divRef = useRef();
  useEffect(() => {
    const lineCount = textAreaValue.split(/\r?\n/).length;
    var arr = [];
    for (let i = 0; i < lineCount; i++) {
      arr.push(i + 1);
    }
    setLines(arr);
  }, [textAreaValue]);

  const handleCloseExampleList = () => {
    setShowExampleList(false);
  };

  const { data, isError, isLoading } = useFeeData({
    chainId: currentNetwork?.chainId || 1,
    watch: true,
  });

  const [gasprice, setgasprice] = useState(null);

  useEffect(() => {
    if (data) {
      setgasprice(Number(Number(data.formatted.gasPrice).toFixed(2)));
    }
  }, [data]);

  const findNetwork = (chainIdToFind) => {
    return networkInfo.find((e) => e.chainId == chainIdToFind);
  };

  const handleTextareaScroll = () => {
    if (divRef.current) {
      divRef.current.scrollTop = textareaRef.current.scrollTop;
    }
  };

  const switchNetworkFE = async (chainId) => {
    try {
      await switchNetwork({
        chainId: chainId,
      });
    } catch (error) {
      toast.error("Please confirm network change");
    }
  };

  useEffect(() => {
    const checkNetwork = () => {
      if (isConnected && chain) {
        const networkFinded = findNetwork(chain.id);
        setCurrentNetwork(networkFinded);
      } else {
        setCurrentNetwork(networkInfo[0]);
      }
    };
    checkNetwork();
  }, [chain, isConnected]);

  useEffect(() => {
    watchNetwork((network) => {
      try {
        if (chain && isConnected && network) {
          const networkFinded = findNetwork(network.chain.id);
          setCurrentNetwork(networkFinded);
        } else {
          setCurrentNetwork(null);
        }
      } catch (error) {}
    });
  }, []);

  useEffect(() => {
    if (currentNetwork && chain && currentNetwork.chainId != chain.id) {
      switchNetworkFE(currentNetwork.chainId);
    }
  }, [currentNetwork]);

  //want to go next
  const checkNextStep = async () => {
    if (!selectedToken) {
      toast.error("Please select a token to send");
      return;
    }
    if (erc721) {
      const next = processDataErc721(
        textAreaValue,
        setErrorArray,
        setMergeArray,
        setDataArray,
        errorArray,
        mergeArray,
        setchecking,
        setTextAreaValue,
        setErrorIndex
      );
      if (next) {
        setWantToGoNext(true);
        setchecking(true);
      }
    } else {
      const next = processDataErc1155(
        textAreaValue,
        setErrorArray,
        setMergeArray,
        setDataArray,
        errorArray,
        mergeArray,
        setchecking,
        setTextAreaValue,
        setErrorIndex
      );
      if (next) {
        setWantToGoNext(true);
        setchecking(true);
      }
    }
  };

  //check dataArray and other inputs if ready run preparingForNextStep

  useEffect(() => {
    if (wantToGoNext) {
      if (dataArray?.length > 0 && currentNetwork) {
        if (erc721) {
          prepareForNextStepErc721(
            setchecking,
            selectedToken,
            dataArray,
            setStep,
            address,
            setErrorArray,
            setsendingInfo,
            currentNetwork,
            sendingInfo
          );
        } else {
          prepareForNextStepErc1155(
            setchecking,
            selectedToken,
            dataArray,
            setStep,
            address,
            setErrorArray,
            setsendingInfo,
            currentNetwork,
            sendingInfo
          );
        }
      }
    }
  }, [wantToGoNext, dataArray, selectedToken?.value, currentNetwork?.chainId]);

  useEffect(() => {
    if (
      wantToGoNext &&
      selectedToken?.value &&
      dataArray?.length &&
      currentNetwork.chainId &&
      sendingInfo &&
      sendingInfo?.totalAmount
    ) {
      setchecking(false);
      setWantToGoNext(false);
      setStep((pre) => pre + 1);
    }
  }, [
    wantToGoNext,
    dataArray?.length,
    selectedToken?.value,
    currentNetwork,
    sendingInfo,
  ]);

  useEffect(() => {
    if (reCalculate > 0) {
      if (erc721) {
        prepareForNextStepErc721(
          setchecking,
          selectedToken,
          dataArray,
          setStep,
          address,
          setErrorArray,
          setsendingInfo,
          currentNetwork,
          sendingInfo
        );
      } else {
        prepareForNextStepErc1155(
          setchecking,
          selectedToken,
          dataArray,
          setStep,
          address,
          setErrorArray,
          setsendingInfo,
          currentNetwork,
          sendingInfo
        );
      }
    }
  }, [reCalculate, erc721]);

  return (
    <>
      <div className="inner-page home2">
        {<PageTitle title="nftsender" desc={"NFT Batchsender"} />}

        <section className="tf-section project-info pb60 pt60">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="project-info-form check-radio">
                  {step == 0 && (
                    <>
                      <h6 className="title mb20">Networks</h6>
                      <NetworkList
                        currentNetwork={currentNetwork}
                        setCurrentNetwork={setCurrentNetwork}
                        isConnected={isConnected}
                        chain={chain}
                      />
                      <div className="row mt-3">
                        <div className="col-md-6">
                          {gasprice && (
                            <ReactSpeedometer
                              width={200}
                              height={150}
                              segments={3}
                              minValue={0}
                              maxValue={300}
                              segmentColors={["green", "yellow", "red"]}
                              value={gasprice}
                              currentValueText={`${gasprice} GWei`}
                            />
                          )}
                        </div>
                        <div
                          className="col-md-6 mt-4"
                          style={{ fontSize: "0.7rem" }}
                        >
                          <h5>Current Gas Price</h5>
                          <h5>For {currentNetwork?.name || "Ethereum"}</h5>
                        </div>
                      </div>
                      <div className="form-inner">
                        <div className="mt-3 mb-3">
                          <div className="row">
                            <div className="col-md-9 col-7 mb-1">
                              <CustomToolTip
                                title="Please past select your nft from list or past contract address."
                                id="tokencontracttooltip"
                                text="NFT Contract Address"
                              />
                            </div>
                            <div
                              className="col-md-3 col-5 mb-1"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <CustomToolTip
                                title="You can choose ERC721 or ERC1155 NFT's"
                                id="deflationary"
                                text={erc721 ? "ERC721" : "ERC1155"}
                              />
                            </div>
                            <div
                              className="col-md-10 col-9"
                              style={{ paddingLeft: "1rem" }}
                            >
                              <SelectNft
                                disabled={!isConnected}
                                currentNetwork={currentNetwork}
                                address={address}
                                selectedToken={selectedToken}
                                setSelectedToken={setSelectedToken}
                                erc721={erc721}
                              />
                            </div>
                            <div
                              className="col-md-2 mt-1 col-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <CustomSwitch
                                checked={erc721}
                                onChange={() => seterc721((pre) => !pre)}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 mt-3">
                              <fieldset className="block">
                                <div className="box">
                                  <div className="upload-img style2">
                                    <div className="col mobile-mb0">
                                      <div className="box">
                                        <input
                                          type="file"
                                          name="file-4[]"
                                          id="file-4"
                                          className="inputfile inputfile-1"
                                          data-multiple-caption="{count} files selected"
                                          multiple=""
                                          onChange={(event) =>
                                            getCsv(event, setTextAreaValue)
                                          }
                                          accept=".csv"
                                        />
                                        <label htmlFor="file-4">
                                          <span
                                            style={{
                                              marginRight: "40px",
                                              color: "white",
                                            }}
                                          >
                                            Upload CSV
                                          </span>
                                          <svg
                                            width="28"
                                            height="22"
                                            viewBox="0 0 28 22"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M13.3055 2.4375C9.81376 2.4375 6.94481 5.1088 6.63243 8.51863C6.5875 9.00913 6.17579 9.3825 5.68546 9.382C3.92025 9.3805 2.4375 10.8496 2.4375 12.6111C2.4375 14.3945 3.88325 15.8403 5.66666 15.8403H6.5C7.01776 15.8403 7.4375 16.26 7.4375 16.7778C7.4375 17.2955 7.01776 17.7153 6.5 17.7153H5.66666C2.84771 17.7153 0.5625 15.4301 0.5625 12.6111C0.5625 10.0805 2.45709 7.9655 4.87203 7.5725C5.60853 3.58349 9.10378 0.5625 13.3055 0.5625C16.4338 0.5625 19.1693 2.23737 20.6668 4.73581C24.253 4.59444 27.4375 7.52788 27.4375 11.2223C27.4375 14.5896 24.8748 17.3574 21.5926 17.6833C21.0774 17.7344 20.6183 17.3583 20.5671 16.843C20.5159 16.3278 20.8921 15.8686 21.4074 15.8174C23.74 15.5859 25.5625 13.6163 25.5625 11.2223C25.5625 8.45625 23.004 6.2614 20.2826 6.65113C19.8821 6.7085 19.4839 6.5055 19.2988 6.13691C18.1965 3.94114 15.9258 2.4375 13.3055 2.4375Z"
                                              fill="#798DA3"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M14 12.8258L16.462 15.2878C16.8281 15.6539 17.4217 15.6539 17.7879 15.2878C18.154 14.9216 18.154 14.3281 17.7879 13.962L15.1049 11.2789C14.4946 10.6688 13.5054 10.6688 12.8951 11.2789L10.2121 13.962C9.84597 14.3281 9.84597 14.9216 10.2121 15.2878C10.5782 15.6539 11.1718 15.6539 11.5379 15.2878L14 12.8258Z"
                                              fill="#798DA3"
                                            />
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M14 21.1875C14.5178 21.1875 14.9375 20.7677 14.9375 20.25V12.75C14.9375 12.2322 14.5178 11.8125 14 11.8125C13.4822 11.8125 13.0625 12.2322 13.0625 12.75V20.25C13.0625 20.7677 13.4822 21.1875 14 21.1875Z"
                                              fill="#798DA3"
                                            />
                                          </svg>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                            </div>
                            <div
                              className="col-6 mt-3"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  cursor: "pointer",
                                  marginTop: "1rem",
                                  color: "white",
                                  "&:hover": {
                                    color: "#84EF17",
                                    fontSize: "26px",
                                  },
                                }}
                                onClick={() => setShowExampleList(true)}
                              >
                                Show Example List
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            borderRadius: "15px",
                            border: "1px solid #383632",
                          }}
                        >
                          <div
                            className="col-1 text-center line-count"
                            style={{
                              height: "300px",
                              overflow: "auto",
                              padding: "5px",
                            }}
                            ref={divRef}
                          >
                            {lines &&
                              lines.map((e, index) => (
                                <span
                                  key={e}
                                  style={{
                                    color:
                                      (errorIndex.includes(index) ||
                                        mergeArray.flat().includes(index)) &&
                                      "red",
                                  }}
                                >
                                  {e}
                                  <br />
                                </span>
                              ))}
                          </div>
                          <div className="col-11">
                            <textarea
                              style={{
                                height: "300px",
                                resize: "none",
                                margin: 0,
                                padding: "5px",
                                width: "100%",
                                color: "white",
                                borderRadius: 0,
                              }}
                              rows={8}
                              onScroll={handleTextareaScroll}
                              ref={textareaRef}
                              className="custom-textarea lined"
                              id="lined"
                              value={textAreaValue}
                              onChange={(e) => {
                                setTextAreaValue(e.target.value);
                              }}
                            />
                          </div>
                        </div>

                        {mergeArray.length > 0 && (
                          <div className="col-md-12 mt-3">
                            <div className="form-inner">
                              <ul
                                className="list"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <li
                                  className="list__item"
                                  onClick={() =>
                                    mergeDuplicatesErc1155(
                                      mergeArray,
                                      dataArray,
                                      setTextAreaValue,
                                      setMergeArray,
                                      setDataArray,
                                      setErrorArray
                                    )
                                  }
                                  key={"li1"}
                                >
                                  <input
                                    type="radio"
                                    className="radio-btn"
                                    name={"somename"}
                                    id={"a-opt1"}
                                  />
                                  <label htmlFor={"a-opt1"} className="label">
                                    <span>{"Merge Amounts"}</span>
                                  </label>
                                </li>
                                <li
                                  className="list__item"
                                  onClick={() =>
                                    removeDuplicatesErc1155(
                                      mergeArray,
                                      dataArray,
                                      setTextAreaValue,
                                      setMergeArray,
                                      setDataArray,
                                      setErrorArray
                                    )
                                  }
                                  key={"li2"}
                                >
                                  <input
                                    type="radio"
                                    className="radio-btn"
                                    name={"somename"}
                                    id={"a-opt1"}
                                  />
                                  <label htmlFor={"a-opt1"} className="label">
                                    <span>{"Remove Copies"}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}

                        {errorArray &&
                          errorArray.map((e) => (
                            <div
                              className="col-md-12 mt-3 mb-3"
                              key={e.message}
                            >
                              <WarningAlert text={e.message} />
                            </div>
                          ))}
                        {mergeArray &&
                          mergeArray.map((e, index) => (
                            <div className="col-md-12 mt-3 mb-3" key={index}>
                              {erc721 ? (
                                <WarningAlert
                                  text={`Duplicate token Id's found on lines ${e.map(
                                    (f) => f + 1
                                  )}`}
                                />
                              ) : (
                                <WarningAlert
                                  text={`Duplicate token Address and Id's found on lines ${e.map(
                                    (f) => f + 1
                                  )}`}
                                />
                              )}
                            </div>
                          ))}

                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "15px",
                          }}
                        >
                          {!isConnected ? (
                            <ConnectButton />
                          ) : checking ? (
                            <Spinner animation="border" />
                          ) : (
                            <Button
                              onClick={checkNextStep}
                              addclass="style1"
                              title="Next Step >>"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {step == 1 && (
                    <>
                      {erc721 ? (
                        <NftSenderSecondStep
                          sendingInfo={sendingInfo}
                          currentNetwork={currentNetwork}
                          selectedToken={selectedToken}
                          dataArray={dataArray}
                          setreCalculate={setreCalculate}
                        />
                      ) : (
                        <NftSenderSecondStep1155
                          sendingInfo={sendingInfo}
                          currentNetwork={currentNetwork}
                          selectedToken={selectedToken}
                          dataArray={dataArray}
                          setreCalculate={setreCalculate}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <ExampleListModal
        show={showExampleList}
        handleClose={handleCloseExampleList}
        erc721={erc721 ? "ERC721" : "ERC1155"}
      />
    </>
  );
}

export default Nftsender;
