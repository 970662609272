import React, { useEffect } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import AOS from "aos";
import "../src/assets/fonts/font-awesome.css";
import Home from "./cpages/Home";
import Contact from "./cpages/Contact";
import Header from "./cpages/components/header";
import Footer from "./cpages/components/footer";
import AppEntry from "./cpages/AppEntry";
import Tokensender from "./cpages/Tokensender";
import { WagmiConfig, createConfig } from "wagmi";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";

import {
  GlobalStateProvider,
  useGlobalState,
} from "./cpages/hooks/globalState";
import {
  polygon,
  bsc,
  mainnet,
  fantom,
  sepolia,
  avalanche,
  base,
  optimism,
  linea,
  gnosis,
  arbitrum,
} from "@wagmi/core/chains";
import {
  ConnectKitProvider,
  ConnectKitButton,
  getDefaultConfig,
} from "connectkit";
import Nftsender from "./cpages/Nftsender";
import Referral from "./cpages/Referral";
import info from "./cpages/info";

const theme = {
  "--ck-font-family": "Inter",
  "--ck-font-weight": "400",
  "--ck-border-radius": "18px",
  "--ck-overlay-backdrop-filter": "blur(0px)",
  "--ck-modal-heading-font-weight": "500",
  "--ck-qr-border-radius": "16px",
  "--ck-connectbutton-font-size": "15px",
  "--ck-connectbutton-color": "#373737",
  "--ck-connectbutton-background": "#F6F7F9",
  "--ck-connectbutton-background-secondary": "#FFFFFF",
  "--ck-connectbutton-border-radius": "16px",
  "--ck-connectbutton-box-shadow": "0 0 0 0 #ffffff",
  "--ck-connectbutton-hover-color": "#373737",
  "--ck-connectbutton-hover-background": "#F0F2F5",
  "--ck-connectbutton-hover-box-shadow": "0 0 0 0 #ffffff",
  "--ck-connectbutton-active-color": "#373737",
  "--ck-connectbutton-active-background": "#EAECF1",
  "--ck-connectbutton-active-box-shadow": "0 0 0 0 #ffffff",
  "--ck-connectbutton-balance-color": "#373737",
  "--ck-connectbutton-balance-background": "#fff",
  "--ck-connectbutton-balance-box-shadow": "inset 0 0 0 1px #F6F7F9",
  "--ck-connectbutton-balance-hover-background": "#F6F7F9",
  "--ck-connectbutton-balance-hover-box-shadow": "inset 0 0 0 1px #F0F2F5",
  "--ck-connectbutton-balance-active-background": "#F0F2F5",
  "--ck-connectbutton-balance-active-box-shadow": "inset 0 0 0 1px #EAECF1",
  "--ck-primary-button-font-weight": "600",
  "--ck-primary-button-border-radius": "16px",
  "--ck-primary-button-color": "#FFFFFF",
  "--ck-primary-button-background": "black",
  "--ck-primary-button-box-shadow": "0 0 0 0 #ffffff",
  "--ck-primary-button-hover-color": "#000000",
  "--ck-primary-button-hover-background": "#00FFFF",
  "--ck-primary-button-hover-box-shadow": "0 0 0 0 #ffffff",
  "--ck-primary-button-active-color": "#373737",
  "--ck-primary-button-active-background": "#798da3",
  "--ck-primary-button-active-box-shadow": "0 0 0 0 #ffffff",
  "--ck-secondary-button-font-weight": "500",
  "--ck-secondary-button-border-radius": "16px",
  "--ck-secondary-button-color": "#FFFFFF",
  "--ck-secondary-button-background": "#020E4B",
  "--ck-secondary-button-box-shadow": "0 0 0 0 #ffffff",
  "--ck-secondary-button-hover-color": "#373737",
  "--ck-secondary-button-hover-background": "#00FFFF",
  "--ck-secondary-button-hover-box-shadow": "0 0 0 0 #ffffff",
  "--ck-secondary-button-active-color": "#373737",
  "--ck-secondary-button-active-background": "#798da3",
  "--ck-secondary-button-active-box-shadow": "0 0 0 0 #ffffff",
  "--ck-tertiary-button-font-weight": "500",
  "--ck-tertiary-button-border-radius": "16px",
  "--ck-tertiary-button-color": "#FFFFFF",
  "--ck-tertiary-button-background": "#F6F7F9",
  "--ck-tertiary-button-box-shadow": "0 0 0 0 #ffffff",
  "--ck-tertiary-button-hover-color": "#373737",
  "--ck-tertiary-button-hover-background": "#F6F7F9",
  "--ck-tertiary-button-hover-box-shadow": "0 0 0 0 #ffffff",
  "--ck-tertiary-button-active-color": "#373737",
  "--ck-tertiary-button-active-background": "#F6F7F9",
  "--ck-tertiary-button-active-box-shadow": "0 0 0 0 #ffffff",
  "--ck-modal-box-shadow": "0px 2px 4px 0px #00000005",
  "--ck-overlay-background": "#05a3f121",
  "--ck-body-color": "#FFFFFF",
  "--ck-body-color-muted": "#999999",
  "--ck-body-color-muted-hover": "#00FFFF",
  "--ck-body-background": "#020E4B",
  "--ck-body-background-transparent": "rgba(255,255,255,0)",
  "--ck-body-background-secondary": "#1b265d",
  "--ck-body-background-secondary-hover-background": "#e0e4eb",
  "--ck-body-background-secondary-hover-outline": "#4282FF",
  "--ck-body-background-tertiary": "#bfc6dc",
  "--ck-body-action-color": "#999999",
  "--ck-body-divider": "#f7f6f8",
  "--ck-body-color-danger": "#FF4E4E",
  "--ck-body-color-valid": "#32D74B",
  "--ck-siwe-border": "#F0F0F0",
  "--ck-body-disclaimer-background": "#f6f7f9",
  "--ck-body-disclaimer-color": "#AAAAAB",
  "--ck-body-disclaimer-link-color": "#838485",
  "--ck-body-disclaimer-link-hover-color": "#000000",
  "--ck-tooltip-background": "#000E4E",
  "--ck-tooltip-background-secondary": "#ffffff",
  "--ck-tooltip-color": "#999999",
  "--ck-tooltip-shadow": "0px 2px 10px 0 #00000014",
  "--ck-dropdown-button-color": "#999999",
  "--ck-dropdown-button-box-shadow":
    "0 0 0 1px rgba(0,0,0,0.01), 0px 0px 7px rgba(0, 0, 0, 0.05)",
  "--ck-dropdown-button-background": "#000E4E",
  "--ck-dropdown-button-hover-color": "#8B8B8B",
  "--ck-dropdown-button-hover-background": "#F5F7F9",
  "--ck-qr-dot-color": "#000000",
  "--ck-qr-background": "#000E4E",
  "--ck-qr-border-color": "#f7f6f8",
  "--ck-focus-color": "#1A88F8",
  "--ck-spinner-color": "#1A88F8",
  "--ck-copytoclipboard-stroke": "#CCCCCC",
  "--ck-recent-badge-color": "#777",
  "--ck-recent-badge-background": "#F6F7F9",
  "--ck-recent-badge-border-radius": "32px",
};

function App1() {
  const { globalState, setGlobalState } = useGlobalState();

  const config = createConfig(
    getDefaultConfig({
      // Required API Keys
      alchemyId: "BwE39efXkhwLUi3Zla2UthiCF38_upT3", // or infuraId
      walletConnectProjectId: "734adb9155ad6e52e3c09a08880f1bb7",

      // Required
      appName: "Your App Name",

      // Optional
      chains: [
        mainnet,
        polygon,
        bsc,
        fantom,
        avalanche,
        base,
        optimism,
        linea,
        gnosis,
        arbitrum,
      ],
      appDescription: "Your App Description",
      appUrl: "https://uncw3b.com", // your app's url
      appIcon:
        "https://uncw3b.com/1694615642228-5321572a-477a-4243-9da1-e1782b8d4583.webp", // your app's icon, no bigger than 1024x1024px (max. 1MB)
    })
  );

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <WagmiConfig config={config}>
        <ConnectKitProvider customTheme={theme}>
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/batchsender" element={<Tokensender />} />
            <Route path="/nftsender" element={<Nftsender />} />
            <Route path="/referral" element={<Referral />} />
          </Routes>
          <Footer />
        </ConnectKitProvider>
      </WagmiConfig>
    </>
  );
}

function App2() {
  ReactGA.initialize("G-P8QN78FWQG");

  useEffect(() => {
    const storedRef = localStorage.getItem("ref");
    const defaultRef = info.refWallet;

    const urlParams = new URLSearchParams(window.location.search);
    const urlRef = urlParams.get("ref");

    const tagManagerArgs = {
      gtmId: "G-P8QN78FWQG",
    };

    TagManager.initialize(tagManagerArgs);

    if (storedRef) {
      return;
    } else if (!storedRef && urlRef) {
      if (isValidEthereumAddress(urlRef)) {
        localStorage.setItem("ref", urlRef);
      } else {
        localStorage.setItem("ref", defaultRef);
      }
    } else {
      localStorage.setItem("ref", defaultRef);
    }
  }, []);

  const isValidEthereumAddress = (address) => {
    return /^0x[0-9A-Fa-f]{40}$/i.test(address);
  };

  return (
    <GlobalStateProvider>
      <App1 />
    </GlobalStateProvider>
  );
}

export default App2;
