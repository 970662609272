import React from "react";
import { Alert, Spinner } from "react-bootstrap";

function TxAlert({ text, link, status, noAnimation }) {
  return (
    <Alert variant={status ? status : "warning"} data-bs-theme="dark">
      {!noAnimation && (
        <Spinner
          animation="grow"
          size="sm"
          variant="primary"
          className="mr-1"
        />
      )}
      {link ? (
        <a href={link} target="_blank">
          {text}
        </a>
      ) : (
        { text }
      )}
    </Alert>
  );
}

export default TxAlert;
