import React, { useState } from "react";
import { toast } from "react-toastify";
import AsyncCreatableSelect from "react-select/async-creatable";
import info from "../info";
import { numberToHex } from "../hooks/helpers";
import { useEffect } from "react";
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1b265d",
    border: "1px solid #444",
    color: "white",
    borderRadius: "15px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #444",
    color: "white",
    backgroundColor: "#1b265d",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1b265d", // Açılır menü arka plan rengi
    borderRadius: "15px",
    border: "1px solid #383632",
  }),
  menuList: (provided) => ({
    ...provided,
    color: "white", // Açılır menü yazı rengi
    cursor: "pointer",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Arama çubuğu yazı rengi
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Giriş yazı rengi
    borderRadius: "15px",
  }),
};

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#171F29",
  },
});

const SelectNft = ({
  disabled,
  currentNetwork,
  address,
  selectedToken,
  setSelectedToken,
  erc721,
}) => {
  const CustomOption = ({ data, selectProps, innerRef, innerProps }) => {
    console.log(data);
    if (
      (erc721 && data.contractType == "ERC1155") ||
      (!erc721 && data.contractType == "ERC721")
    ) {
      return null;
    }

    return (
      <div ref={innerRef} {...innerProps} className="row px-1 mt-3 mb-1">
        <div className="col-md-12 col-12">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span>{data.tokenName}</span>
            <span>{data.symbol}</span>
            <span>{data.address}</span>
          </div>
        </div>
        <hr className="mt-1" />
      </div>
    );
  };

  const handleTokenChange = (selectedOption) => {
    setSelectedToken(selectedOption);
  };

  const fetchData = async () => {
    if (disabled || !currentNetwork || !address) return;
    try {
      const hexId = numberToHex(currentNetwork.chainId);
      // API'den verileri çek
      const url =
        info.apiUrl +
        "/api/moralis/nft" +
        "?chain=" +
        hexId +
        "&address=" +
        address;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);
      // Gelen verileri dönüştür
      const options = data.map((item) => ({
        value: item.tokenAddress,
        label: item.name,
        address: item.tokenAddress,
        tokenName: item.name,
        symbol: item.symbol,
        contractType: item.contractType,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentNetwork?.chainId, address, erc721]);

  return (
    <div
      style={{ width: "100%" }}
      onClick={() =>
        disabled && toast.error("Please connect your wallet to choose nft.")
      }
    >
      <AsyncCreatableSelect
        key={`${currentNetwork?.chainId}-${address}`}
        value={selectedToken}
        onChange={handleTokenChange}
        cacheOptions
        defaultOptions
        loadOptions={fetchData}
        components={{ Option: CustomOption }}
        styles={customStyles}
        theme={customTheme}
        isDisabled={disabled}
        isSearchable
        isClearable
        placeholder="Select NFT Collection"
        formatCreateLabel={(inputValue) => `Select NFT address "${inputValue}"`}
        onCreateOption={(val) => {
          setSelectedToken({ value: val, label: val });
        }}
        id="sdfksdlfmn"
        name="sdlfkasdnsd"
      />
    </div>
  );
};

export default SelectNft;
