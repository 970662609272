import { readContract, fetchBalance, erc721ABI } from "@wagmi/core";
import { tokenAbi } from "../abi/tokenAbi";
import BigNumber from "bignumber.js";
import { tokensenderAbi } from "../abi/tokensenderAbi";
import { erc1155abi } from "../abi/erc1155abi";

// Token balance'ına ondalık eklemek için
export function removeDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.dividedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

// Token balance'ından ondalık çıkarmak için
export function addDecimal(balance, decimal, fixed) {
  if (decimal > 0) {
    const balanceBig = new BigNumber(String(balance));
    const decimalBig = new BigNumber(String(10)).exponentiatedBy(
      String(decimal)
    );
    const result = new BigNumber(balanceBig.multipliedBy(decimalBig));
    return result.toFixed(fixed);
  } else {
    const balanceBig = new BigNumber(String(balance));
    return balanceBig.toFixed(fixed);
  }
}

export const getApprove = async (
  walletAddress,
  tokenAddress,
  spenderAddress
) => {
  try {
    const data = await readContract({
      address: tokenAddress,
      abi: tokenAbi,
      functionName: "allowance",
      args: [walletAddress, spenderAddress],
    });
    console.log(data, typeof data, data.toString());
    return data;
  } catch (error) {
    return null;
  }
};

export const getCommissionDecimal = async (contractAddress) => {
  try {
    const data = await readContract({
      address: contractAddress,
      abi: tokensenderAbi,
      functionName: "commission",
    });
    console.log(data, typeof data, data.toString());
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getCommission = async (contractAddress) => {
  try {
    const data = await readContract({
      address: contractAddress,
      abi: tokensenderAbi,
      functionName: "commission",
    });
    return removeDecimal(data.toString(), "18", "5");
  } catch (error) {
    return null;
  }
};

export const getErc721Commission = async (contractAddress) => {
  try {
    const data = await readContract({
      address: contractAddress,
      abi: tokensenderAbi,
      functionName: "erc721commission",
    });
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getErc1155Commission = async (contractAddress) => {
  try {
    const data = await readContract({
      address: contractAddress,
      abi: tokensenderAbi,
      functionName: "erc1155commission",
    });
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getTokenBalance = async (walletAddress, tokenAddress) => {
  try {
    const data = await readContract({
      address: tokenAddress,
      abi: tokenAbi,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const getBalance = async (walletAddress) => {
  try {
    const balance = await fetchBalance({
      address: walletAddress,
    });
    return balance;
  } catch (error) {
    return null;
  }
};

export const getErc721Approve = async (
  walletAddress,
  erc721Address,
  coinwizAddress
) => {
  try {
    const data = await readContract({
      address: erc721Address,
      abi: erc721ABI,
      functionName: "isApprovedForAll",
      args: [walletAddress, coinwizAddress],
    });
    console.log(data, typeof data, data.toString());
    return data;
  } catch (error) {
    return null;
  }
};

export const getErc721Balance = async (walletAddress, tokenAddress) => {
  try {
    const data = await readContract({
      address: tokenAddress,
      abi: erc721ABI,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getErc1155Balance = async (walletAddress, tokenAddress) => {
  try {
    const data = await readContract({
      address: tokenAddress,
      abi: erc1155abi,
      functionName: "balanceOf",
      args: [walletAddress],
    });
    return data.toString();
  } catch (error) {
    return null;
  }
};

export const getErc1155Approve = async (
  walletAddress,
  erc1155Address,
  coinwizAddress
) => {
  try {
    const data = await readContract({
      address: erc1155Address,
      abi: erc1155abi,
      functionName: "isApprovedForAll",
      args: [walletAddress, coinwizAddress],
    });
    console.log(data, typeof data, data.toString());
    return data;
  } catch (error) {
    return null;
  }
};
