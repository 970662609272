import PageTitle from "./components/PageTitle";
import CTA from "./components/Cta";
import ReactGA from "react-ga4";

function Contact() {
  return (
    <div className="inner-page">
      {<PageTitle title="Contact" desc={"Contact Us!"} />}

      <section className="tf-section tf-contact pt60">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className="tf-title mb40"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                <h2 className="title">Get in Touch with us</h2>
                <p>
                  If you have any questions or suggestions for us, please share
                  them with us; we will value your feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="tf-section project-info">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <form
                action="contact/contact-process.php"
                className="form-contact"
              >
                <div className="project-info-form">
                  <h6 className="title">Leave a message</h6>
                  <div className="form-inner">
                    <fieldset>
                      <label>Full name</label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Full Name"
                        required
                      />
                    </fieldset>
                    <fieldset>
                      <label>Email address</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="Your email address"
                        required
                      />
                    </fieldset>

                    <fieldset>
                      <label htmlFor="message">Message</label>
                      <textarea
                        id="message"
                        placeholder="Your message"
                        rows="5"
                        tabIndex="4"
                        name="message"
                        className="message"
                        required
                      ></textarea>
                    </fieldset>
                  </div>
                </div>

                <div className="wrap-btn">
                  <button type="submit" className="tf-button style1">
                    Send mesage
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
