import React, { useState } from "react";
import { toast } from "react-toastify";
import AsyncCreatableSelect from "react-select/async-creatable";
import info from "../info";
import { numberToHex } from "../hooks/helpers";
import noImage from "../../assets/images/noimage.png";
import { removeDecimal, getBalance } from "../hooks/wagmiHooks";
import { useEffect } from "react";
const customStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#1b265d",
    border: "1px solid #444",
    color: "white",
    borderRadius: "15px",
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #444",
    color: "white",
    backgroundColor: "#1b265d",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#1b265d", // Açılır menü arka plan rengi
    borderRadius: "15px",
    border: "1px solid #383632",
  }),
  menuList: (provided) => ({
    ...provided,
    color: "white", // Açılır menü yazı rengi
    cursor: "pointer",
    "::-webkit-scrollbar": {
      width: "4px",
      height: "0px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "white", // Arama çubuğu yazı rengi
  }),
  input: (provided) => ({
    ...provided,
    color: "white", // Giriş yazı rengi
    borderRadius: "15px",
  }),
};

const customTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#171F29",
  },
});

const SelectTokens = ({
  disabled,
  currentNetwork,
  address,
  selectedToken,
  setSelectedToken,
}) => {
  const CustomOption = ({ data, selectProps, innerRef, innerProps }) => {
    return (
      <div ref={innerRef} {...innerProps} className="row px-1 mt-3 mb-1">
        {data.icon ? (
          <div className="col-md-2 col-12">
            <div className="center">
              <img src={data.icon} alt="icon" style={{ width: "20px" }} />
            </div>
          </div>
        ) : (
          <div className="col-md-2 col-12">
            <div className="center">
              <img
                src={noImage}
                alt=""
                style={{ width: "30px", borderRadius: "50%" }}
              />
            </div>
          </div>
        )}
        <div className="col-md-10 col-12">
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <span>{data.tokenName}</span>
            <span>{data.symbol}</span>
            <span>{data.amount}</span>
          </div>
        </div>
        <div className="col-md-12 center">
          <span>{data.address}</span>
        </div>
        <hr className="mt-1" />
      </div>
    );
  };

  const handleTokenChange = (selectedOption) => {
    setSelectedToken(selectedOption);
  };

  const fetchData = async () => {
    if (disabled || !currentNetwork || !address) return;
    try {
      const hexId = numberToHex(currentNetwork.chainId);
      // API'den verileri çek
      const url =
        info.apiUrl +
        "/api/moralis" +
        "?chain=" +
        hexId +
        "&address=" +
        address;
      const response = await fetch(url);
      const data = await response.json();
      const native = await getBalance(address);

      // Gelen verileri dönüştür
      const options = data.data.map((item) => ({
        value: item.token_address,
        label: item.name,
        icon: item.logo,
        address: item.token_address,
        amount:
          Number(item.decimals) > 0
            ? removeDecimal(item.balance, item.decimals, 5)
            : item.balance,
        tokenName: item.name,
        symbol: item.symbol,
        decimals: item.decimals,
      }));

      if (native) {
        const newOpt = [
          {
            value: currentNetwork.currencyAddress,
            label: currentNetwork.currencyName,
            icon: currentNetwork.image,
            address: currentNetwork.currencyAddress,
            amount: Number(native.formatted).toFixed(5),
            tokenName: currentNetwork.currencyName,
            symbol: currentNetwork.currencySymbol,
            decimals: 18,
          },
          ...options,
        ];
        return newOpt;
      }

      return options;
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentNetwork?.chainId, address]);

  return (
    <div
      style={{ width: "100%" }}
      onClick={() =>
        disabled && toast.error("Please connect your wallet to choose token.")
      }
    >
      <AsyncCreatableSelect
        key={`${currentNetwork?.chainId}-${address}`}
        value={selectedToken}
        onChange={handleTokenChange}
        cacheOptions
        defaultOptions
        loadOptions={fetchData}
        components={{ Option: CustomOption }}
        styles={customStyles}
        theme={customTheme}
        isDisabled={disabled}
        isSearchable
        isClearable
        placeholder="Select Token"
        formatCreateLabel={(inputValue) => `Select address "${inputValue}"`}
        onCreateOption={(val) => {
          setSelectedToken({ value: val, label: val });
        }}
        id="jfjfjf"
        name="sdlfknsd"
      />
    </div>
  );
};

export default SelectTokens;
