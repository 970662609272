import React, { useState } from "react";
import { toast } from "react-toastify";
import Papa from "papaparse";

function getCsv(event, setTextAreaValue) {
  const file = event.target.files[0];
  const extension = file.name.split(".").pop();

  if (extension !== "csv") {
    toast.error("Only CSV files allowed");
    return;
  }
  const reader = new FileReader();

  const convertArraysToText = (arrays) => {
    let text = "";
    arrays.forEach((arr) => {
      const cleanArr = arr.map((item) => item.trim());
      text += cleanArr.join(",") + "\n";
    });
    return text;
  };

  reader.onload = (e) => {
    const csv = e.target.result;
    Papa.parse(csv, {
      complete: (result) => {
        setTextAreaValue(convertArraysToText(result.data));
      },
    });
  };

  reader.readAsText(file);
}

export default getCsv;
